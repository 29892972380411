<template>
    <div class="container">
      <div class="setup">
        <span class="title"># 知识树</span>
        <span class="memo">- 提供年级学科目录</span>
        <span class="memo">- 自动生成对应练习</span>
        <span class="memo">- 分析习题涵盖知识点信息</span>
        <span class="memo">- 对应薄弱项生成同类练习题</span>
      </div>

      <div class="psy-feature-indicator-features-title"><span style="margin:20px">小学四年级</span></div>

      <div class="pannel">
        <div class="psy-feature-indicator">
          <div class="psy-feature-indicator-features">
            <!-- <span class="psy-feature-indicator-features-info-title">数学</span> 
            <el-collapse style="width:300px" v-model="activeNames" @change="featuresHandelChange">
              <el-collapse-item v-for="(item,index) in psyFeatures" :key="index" :title="item.title" :name="index+1">
                <span class="psy-feature-indicator-features-info-text">
                  {{item.text}}
                </span>
              </el-collapse-item>
            </el-collapse> -->
            <span class="psy-feature-indicator-features-info-title">数学</span> 
           
            <div class="psy-feature-indicator-indicator">
              <div v-for="(item,index) in psyIndicator" :key="index" class="psy-feature-indicator-indicator-card">
                <div>
                  <img class="psy-feature-indicator-indicator-card-icon" :src="item.icon"/>
                </div>
                <div>
                  <span class="psy-feature-indicator-indicator-card-title">{{item.title}}</span>
                  <span class="psy-feature-indicator-indicator-card-info">{{item.text}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>  

          

        <div class="psy-diagnosis-analysis"> 
            <video controls src="https://r1-ndr-private.ykt.cbern.com.cn/edu_product/esp/assets/0defcbb8-b72d-42f0-a778-158c9831f5ed.t/zh-CN/1703757409446/videos/d48759c9-6d17-4800-bab3-6610d2904fff-1920x1080-true-4c4209784494ce6aef8f262819734164-72c6516788f14e3db7fd7ca8fc8bf4b8-00000.ts"></video>
          <el-scrollbar  style="height:900px">
              <div v-for="(i,index) in historyTalk" :key="index" class="psy-diagnosis-analysis-rebot-item">
                <div v-if="i.rebot!=null" class="psy-diagnosis-analysis-rebot">  
                  <img class="psy-diagnosis-analysis-rebot-avatar" src="https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/rebot.png"/>
                  <span class="psy-diagnosis-analysis-rebot-nickname" >AI 心理老师</span>
                </div>
                <!-- <div v-if="i.rebot!=null||i.rebot!=''" class="psy-diagnosis-analysis-rebot-text" v-html="renderMD"> </div> -->

                <div v-if="i.teacher != null" class="psy-diagnosis-analysis-teacher">  
                  <img class="psy-diagnosis-analysis-teacher-avatar" src="https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/teacher.png"/>
                  <span class="psy-diagnosis-analysis-teacher-nickname" >您</span>
                </div>
               
                <div v-if="i.teacher!=null" class="psy-diagnosis-analysis-teacher-text"> {{ i.teacher}}</div>
                </div>
                <div class="psy-diagnosis-analysis-rebot-text" v-html="renderMD"> </div>
          </el-scrollbar>

          <div class="psy-diagnosis-analysis-input-box">
            <div>
              <el-button text type="info" icon="EditPen" @click="newConversation">新建对话</el-button>
              <el-divider direction="vertical" border-style="solid" />
              <el-button text type="info" icon="DocumentAdd" @click="showIndividualDocument">存档</el-button>
            </div>

            <div class="psy-diagnosis-analysis-input" >
              <el-input style="width: 75%;margin-right:20px" placeholder="欢迎向我提问" v-model="prompt"></el-input>
              <el-button type="primary" @click="sendPrompt" :loading="askRobot" icon="ChatDotSquare">发送</el-button>
            </div>
          </div>
          
        </div>

        <div class="psy-individual-documents">
          <span class="psy-feature-indicator-features-info-title">学生档案</span> 
          <div v-for="(i,index) in psyIDC" :key=index class="psy-individual-documents-card">
            <span class="psy-individual-documents-card-title">{{i.stuName}}</span>
            <span class="psy-individual-documents-card-date">档案创建时间:&nbsp;{{i.createDatetime}}</span>
          </div>
        </div>
      </div>

      <el-dialog v-model="individualDocumentVisible">
        <el-form :model="form">
          <el-form-item label="学生姓名" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="individualDocumentVisible = false">取消</el-button>
            <el-button type="primary" @click="saveIndividualDocument">
              保存
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>   
</template>
   
  <script>
   
  import ElMessage from 'element-plus'
    export default {
        name: 'KnowledgeTree',
        components:{
           
        },
        data(){
            return{
               psyFeatures:[
                {
                  title:"自我意识增强",
                  text:"这个阶段的孩子自我意识逐渐增强，开始更加关注自己的感受和想法，对自己的身份和角色有了更清晰的认识。他们可能会表现出自我要求的表达，希望按照自己的想法去做事。"
                },
                {
                  title:"认知和分辨力提高",
                  text:"这个阶段的认知和分辨力有所提高，能够开始对一些事情进行好坏的分辨。然而，他们的认知和分辨力还未达到成熟水平，对于一些事物的看法可能还相对较片面。"
                },
                {
                  title:"情绪波动大",
                  text:"这个阶段的情绪波动可能较大，容易受到外界干扰和影响。他们可能会因为一些小事而喜怒无常，甚至出现较大的情绪波动。"
                },
                {
                  title:"好奇心旺盛",
                  text:"这个阶段的孩子好奇心旺盛，对世界充满了探索欲望。他们喜欢提出各种问题，并希望通过自己的探索来理解事物的原理和运作方式。"
                },
                {
                  title:"社交能力提升",
                  text:"这个阶段社交能力逐渐提升，他们开始意识到友谊的重要性，并积极寻求与同龄人的互动和合作。"
                }
               ],
               psyIndicator:[
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/1billion.png",
                  title:"大数的认识",
                  text:"洞悉巨量奥秘，开启无限思维空间——认识大数的深远意义"
                },
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/angle.png",
                  title:"角的度量",
                  text:"精确测量，构建几何世界的基石"
                },
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/times.png",
                  title:"三位数乘两位数",
                  text:"数字间的舞蹈，计算能力的飞跃"
                },
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/4edge.png",
                  title:"平行四边形和梯形",
                  text:"形状之美，探索几何变换的奥秘"
                },
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/division.png",
                  title:"除数是两位数的除法",
                  text:"细分智慧，深化算术理解的边界"
                },
                {
                  icon:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/spvue/statistic.png",
                  title:"统计",
                  text:"数据说话，揭示隐藏的规律与真相"
                }
               ],
               prompt:"",
               diagnosisDialog:[
                  {rebot: "您可以向我描述班上孩子的情况，我会帮助您分析他当前的心理状态。"},
                 
               ],
               historyTalk:[{rebot: "您可以向我描述班上孩子的情况，我会帮助您分析他当前的心理状态。"},
                ],
               psyIDC:[
                         
                      ],
                      individualDocumentVisible:false,
                      form:{name:null},
                      psyRes:"",
                      askRobot:false,
              }
        },
        computed:{
          renderMD(){
            return this.$MD.render(this.psyRes==null||this.psyRes==""?"您可以向我描述班上孩子的情况，我会帮助您分析他当前的心理状态。":this.psyRes)
          }  
        },
        methods: {
          showIndividualDocument(){
            this.individualDocumentVisible = true;
            this.form.name=""
          },
          saveIndividualDocument(){
            this.individualDocumentVisible =false
            this.psyIDC.push({stuName:this.form.name,createDatetime:new Date().toLocaleDateString()}) 
          },
          newConversation(){
            this.diagnosisDialog.length=0
            this.diagnosisDialog.push({rebot: "您可以向我描述班上孩子的情况，我会帮助您分析他当前的心理状态。"})
          },
          async sendPrompt(){
            try{
              this.askRobot=true
            // 获取健康建议
              const req_url="http://localhost:8000/ali-psy-talk/"

              const userData={
                prompt:this.prompt,
              }

              const res=await fetch(req_url,{
              method:'POST',
              body:JSON.stringify(userData),
              headers:{
                "Content-Type":"application/json",
              }
            })
            if(!res.ok){
              
              console.log('请求发送失败',res)
              ElMessage({
                type:'error',
                message:'请求发送失败'
              })
            }
            
            this.historyTalk.push({teacher:this.prompt})
            this.prompt=""
            
            const reader=res.body.getReader()
            let {value,done}=await reader.read()
            
            //this.diagnosisDialog.push({rebot:this.psyRes})  
            while (!done) {  
              ({ value, done } = await reader.read());  
              this.psyRes+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
              //this.diagnosisDialog[this.diagnosisDialog.length-1].rebot+=this.psyRes
              if (done) {  
                this.askRobot=false
                break; // 跳出循环，因为流已经结束  
              }  
            }


            this.historyTalk.push({rebot:this.psyRes})

            }catch(e){
              ElMessage({
                type:'error',
                message:e
              }),
              this.askRobot=false
            }
          }
    },
  }
  </script>
   
<style scoped>
.container{
  display: flex;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
  flex-direction: column;
}

.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }
  .title{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  font-size: 14pt;
  color:#7d8084;
  }
  .memo{
  display: flex;
  margin:10px 20px 3px 40px;
  font-size: 10pt;
  font-weight: 400;
  color:#979a9f;
  }
  .memo:last-child{
  margin-bottom: 30px;
  }

  .pannel{
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content:flex-start;
    background: #fff;
    width: 100%;
    border:0 1px solid rgba(68,74,98,0.15);
    border-radius: 2px;
    box-shadow: 0.15px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
    }


  .psy-feature-indicator-features{
    flex:1;
    margin: 20px 20px;
    text-align: left;
    border: 1px solid rgba(153, 156, 165, 0.15);
    border-radius: 6px;
    box-shadow: inset 0px 1px 1px 1px rgba(153,156,165, 0.05);
    padding: 0 20px;
  }
  .psy-feature-indicator-features-title{
    font-size: 13pt;
    font-weight:900;
    color:#000;
    display: flex;
    width:100%;
    margin: 0 10px;
    text-align: left;
    background: #fff;
    border-radius:0 5px 5px 0;
    border-left: 3px solid #3269ea;
  }
.psy-feature-indicator-features-info{
  text-align: left;
  margin: 0 10px;
}

.psy-feature-indicator-features-info-title{
  display: block;
  margin: 20px 0px 10px 0px;
  font-size:13pt ;
  font-weight: 600;
  word-wrap: break-word;
}
.psy-feature-indicator-features-info-text{
  display: block;
  font-size:10pt ;
  color:#363738;
  font-weight: 300;
  word-wrap: break-word;
}
.psy-feature-indicator{
    
}
.psy-feature-indicator-indicator{
    
}

.psy-feature-indicator-indicator-card{
  display: flex;
  flex-direction: row;
  background: #f9fafe;
  border-radius: 6px;
  margin: 20px 0;
  width: 300px;
}
.psy-feature-indicator-indicator-card-icon{
  margin:20px;
  width: 30px;
  height:30px;
  background: #fff;
  border-radius: 50px;
}
.psy-feature-indicator-indicator-card-title{
  display: block;
  margin: 20px 0px 5px 0px;
  font-size:12pt ;
  font-weight: 600;
  word-wrap: break-word; 
}
.psy-feature-indicator-indicator-card-info{
  display: block;
  font-size:10pt ;
  color:#363738;
  font-weight: 450;
  word-wrap: break-word; 
  margin:0 20px 10px 0;
}
.psy-diagnosis-analysis{
   flex:3;
   margin: 20px 0;
   text-align: left;
   border: 1px solid rgba(153, 156, 165, 0.15);
   border-radius: 6px;
   box-shadow: inset 0px 1px 1px 1px rgba(153,156,165, 0.05);
   padding: 0 20px; 
  }

.psy-diagnosis-analysis-rebot-item{
  display: flex;
  flex-direction: column; 
 margin: 20px 0;
 }

.psy-diagnosis-analysis-rebot{
 display: flex;
 flex-direction: row; 
}
.psy-diagnosis-analysis-rebot-avatar{
  width: 25px;
  height: 25px;
  margin: 10px 5px;
}
.psy-diagnosis-analysis-rebot-nickname{
  font-size: 11pt;
  font-weight: 550;
  display: inline-block;
  margin:10px;
}
.psy-diagnosis-analysis-rebot-text{
  background: #7e49de;
  color:#fff;
  font-size:11pt;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 8px;
  width:60%;
}
.psy-diagnosis-analysis-teacher{
 display: flex;
 flex-direction: row; 
 justify-content: flex-end;
}
.psy-diagnosis-analysis-teacher-avatar{
  width: 25px;
  height: 25px;
  margin: 10px 5px;
}
.psy-diagnosis-analysis-teacher-nickname{
  font-size: 11pt;
  font-weight: 550;
  display: inline-block;
  margin:10px;
}
.psy-diagnosis-analysis-teacher-text{
  background: #f9fafe;
  color:#000;
  font-size:11pt;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 8px;
  width:60%;
  margin-left:auto;
}
.psy-diagnosis-analysis-input-box{
  background: #f5f6fa;
  border-radius: 6px;
  margin:20px 0;
}
.psy-diagnosis-analysis-input{
  padding:10px 10px 20px 10px;
}
.psy-individual-documents{
    flex:1;
    margin: 20px 20px;
    text-align: left;
    border: 1px solid rgba(153, 156, 165, 0.15);
    border-radius: 6px;
    box-shadow: inset 0px 1px 1px 1px rgba(153,156,165, 0.05);
    padding: 0 20px;
  }


.psy-individual-documents-card{
  display: flex;
  background: #f9fafe;
  border-radius: 6px;
  margin: 20px 0;
  width: 300px;
  flex-direction: column;
}
.psy-individual-documents-card-title{
  display: inline-block;
  margin: 20px 10px 10px 10px;
  font-size: 12pt;
  font-weight: 600;
}
.psy-individual-documents-card-date{
  display: inline-block;
  font-size: 10pt;
  margin: 0 10px 10px 10px;;
  color:#7d8084;
}
</style>