<template>
    <div class="container">

        <div class="top">
            <div class="top-top">
                <div class="top-top-left">
                    <span class="top-top-left-subtitle">学习是成长的过程，用心去学，才能收获满载。</span>
                 </div>
                <div class="top-top-right">
                    <span class="top-top-right-subtitle">勤学不辍&nbsp;&nbsp;水滴石穿</span>
                </div>
            </div>

            <div class="top-bottom">
                <span class="top-bottom-title">学习历程</span>
            </div>
        </div>

        <div class="middle">
            <span>学记</span>

            <span class="text">余自幼慕学，尝闻先贤云：“学如逆水行舟，不进则退。”是以孜孜不倦，日夜以继。今略陈所学之心得，庶几不负韶华，亦期与同志共勉之。
            </span>
            <span class="text">夫学之道，首在立志。志不立，则心无所主，虽有百技，无所施也。故当笃志于学，矢志不渝，如磨铁成针，非一日之功。立志既定，则心有所向，行有所依，虽道阻且长，亦无所惧矣。
            </span>
            <span class="text">次则勤勉。古人云：“书山有路勤为径，学海无涯苦作舟。”勤勉者，非徒劳力之勤，亦心之勤也。当以勤勉自励，日夜不辍，如春蚕吐丝，绵绵不绝。如此，则日积月累，自有进益。
            </span>
            <span class="text">又须善思。学而不思则罔，思而不学则殆。善思者，能于纷纭万象中，见微知著，触类旁通。故当博学而审问之，慎思而明辨之，方能得学问之真谛。
            </span>
            <span class="text">且夫学问之道，贵在实践。纸上得来终觉浅，绝知此事要躬行。所学非徒以记诵为能，亦须身体力行，方能知其然，又知其所以然。故当以所学，施于实事，以求实效。
            </span>
            <span class="text">再者，交友亦学问之一端。古人云：“独学而无友，则孤陋而寡闻。”故当广交天下英才，切磋琢磨，以资学问之进益。且朋友之间，相互砥砺，则志气愈坚，学问愈深。
            </span>
            <span class="text">最后，当持之以恒。学问之道，无有穷尽。一日不学，则一日无所得。故当持之以恒，无有间断。如流水之不息，则终必至于江海；如松柏之常青，则岁寒而后知。
            </span>
            <span class="text">总之，学问之道，博大精深，非一言可尽。然立志、勤勉、善思、实践、交友、持恒，此六者，乃学问之要也。愿吾辈共勉之，以成学问之大业。
            </span>        
        </div>

        <div class="footer">
            <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">京ICP备16053548号-1</a>
        </div>

    </div>
</template>
   
  <script>
   
    export default {
        name: 'PersonalStage',
        components:{
         
        },
        data(){
            return{
                    
            }
        },
        computed:{
           
        },
        methods: {
          
    },
  }
  </script>
   
<style scoped>

.container{
    display: flex;
    margin-top:-60px;
    height: 100vh;
    flex-direction: column;
}
.top{
    background-image: url("../assets/mockIndexImg.png");
    background-size: cover;
    display: flex;
    height:75%;
    width: 100%;
    background-position: center;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 20px 20px 0 0 ;
}
.top-top{
    display: flex;
    flex-direction: row;
}
.top-top-left{
    margin: 200px 100px;
    display: flex;
}
.top-top-left-subtitle{
    font-size:11pt;
    font-weight: 500;
    color: #fff;
    width: 200px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.top-top-right{
    margin: 200px 350px;
    display: flex;
}
.top-top-right-subtitle{
    color: #fff;
    font-family:Arial, Helvetica, sans-serif;
    font-size:35pt;
    font-weight: 700;
    width:400px;
}
.top-bottom{
    position: relative;  
    display: inline-block; /* 或者其他适合你的布局的display值 */  
    overflow: hidden; /* 确保模糊效果不会溢出容器 */  
    height: 200px;
}
.top-bottom::after {  
    content: "";  
    position: absolute;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    background: rgba(255, 255, 255, 0.45); /* 你可以根据需要调整颜色和不透明度 */  
    filter: blur(8px); /* 模糊程度，你可以根据需要调整这个值 */  
    z-index: 1; /* 确保模糊层在内容之上 */  
}  
.top-bottom-title{
    display: block; /* 避免底部有间隙 */  
    width: 100px;
    height: auto; /* 保持图片比例 */  
    z-index: 0; /* 确保图片在模糊层之下 */
    color: #fff;  
    margin-top:30px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    padding: 0 20px;
    margin: 35px 45%;
}
.middle{
    margin: 20px 0;
}
.text{
    display: inline-block;
    margin:10px 200px;
    text-align: left;
}
.text:last-child{
    margin-bottom: 50px;
}
.footer{
    display: flex;
    justify-content: center;
}
</style>