<template>
    <div>
        <h1>视频分割打点</h1>
<div class="VideoPreview">
    <div   class="VideoPreview_Header"> 
        <el-text class="VideoPreview_Header_Text">视频预览窗口</el-text>
    </div>

    <div  class="VideoPreview_Screen">
        <video controls :poster="videoPoster" type="video/mp4" class="VideoPreview_Screen_Player" :src="movie">您的浏览器不支持播放在线视频</video>
    </div>

    <div class="VideoPreview_OP">  
        <div class="VideoPreview_OP">
                <el-button  type="primary" @click="analysising">预测分析</el-button>      
        </div>
    </div>      
</div>
   
        <div class="VideoPreview_Cut_Time_Axis"> 
            <div class="VideoPreview_Cut_Time_Axis_Header">
                <span class="VideoPreview_Cut_Time_Axis_Header_Title">时间轴打点</span>
                <span class="VideoPreview_Cut_Time_Axis_Header_Subtitle">仅对持续时间大于5秒的片段进行打点</span>
            </div>
            <div class="VideoPreview_Cut_Time_Axis_Example">图例：
                开始标记:<span class="VideoPreview_Cut_Time_Axis_Mark_Example_Start">S</span>
                结束标记:<span class="VideoPreview_Cut_Time_Axis_Mark_Example_End">E</span>
            </div>
            
            <div class="VideoPreview_Cut_Time_Axis_Mark_Container">
                <div v-for="(r,index) in nodeS" :key="index" class="VideoPreview_Cut_Time_Axis_Mark">
                    <span v-for="(s,sid) in r" :key="sid" :style="computeStyle(s,index)" class="VideoPreview_Cut_Time_Axis_Mark_Start">S-{{index+1}}</span>
                    <span v-for="(e,eid) in nodeE[index]" :style="computeStyle(e,index)" :key="eid"  class="VideoPreview_Cut_Time_Axis_Mark_End">E-{{index+1}}</span>
                </div> 
            </div>
        
            <div class="VideoPreview_Cut_Time_Axis_Axis"></div>
        </div>
        
        <el-scrollbar class="VideoPreview_Analysiser">
            <div class="VideoPreview_Analysiser">
                <span class="VideoPreview_Analysiser_Text">打点依据</span>
        
                <div class="VideoPreview_Analysiser_Row">
        
                    <div v-for="(s,index) in snap" :key=index  class="VideoPreview_Analysiser_Column">
                        <span class="VideoPreview_Analysiser_Column_Title">场景{{index+1}}</span> 
                        <div class="VideoPreview_Analysiser_Column_Snap">
                            <span class="VideoPreview_Analysiser_Column_Snap_Title">随机抽帧预览</span>
                            <div v-if="s!=''">
                                <img class="VideoPreview_Analysiser_Column_Snap_IMG" :src="s"/>
                                <div class="VideoPreview_Analysiser_Column_Params">
                                    <div  class="VideoPreview_Analysiser_Column_frameInfo"> 
                                        <div v-for="(f,i) in dataset[index]" :key=i>
                                            <span>第</span>{{ f }}<span>帧</span>
                                        </div> 
                                    </div>
                                </div>
                                
                            </div>
                            <div v-else >
                                <img class="VideoPreview_Analysiser_Column_Snap_NDIMG" :src="nodata" />
                            </div>
                            </div>
                        
                    </div>
        
                </div>
        
            </div>
            </el-scrollbar>


</div>
</template>

<script>
import {ElLoading} from 'element-plus'
export default {
    data(){
        return{
            movie:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/1630999639787373.mp4",
                    videoPoster:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/videoPoster.svg",
                    emptySize:45,
                    snap:["https://sam-test.oss-cn-beijing.aliyuncs.com/assets/hwtast/2115.png",
                        "https://sam-test.oss-cn-beijing.aliyuncs.com/assets/hwtast/2116.png",
                        "",
                        "https://sam-test.oss-cn-beijing.aliyuncs.com/assets/hwtast/2118.png",
                        "https://sam-test.oss-cn-beijing.aliyuncs.com/assets/hwtast/2119.png"],
                    nodata:"https://sam-test.oss-cn-beijing.aliyuncs.com/assets/hwtast/nodata.png",
                    dataset:[
                        ["85~88","170~170","359~359","360~1171","1662~1667","1806~1806","1826~1849","1851~1851","1890~1891","1895~1895","2039~2039","2048~2048","2053~2056","2078~2080","2086~2104","2111~2112","2114~2183","2198~2200","2204~2221","2223~2224","2228~2228","2232~2233","2238~2238","2240~2247","2249~2275","2492~2494","2528~2528","2587~2587","2589~2589","2599~2599","2606~2606","2608~2615","2619~2619"],
                        ["1~84","89~169","171~358","1173~1177","1179~1179","1181~1618","1623~1629","1633~1636","1650~1661","1668~1737","1759~1780","1783~1783","1785~1805","1807~1810","1812~1825","1862~1889","1913~1913","1916~2033","2035~2036","2057~2057","2063~2066","2070~2077","2081~2085","2107~2107","2197~2197","2342~2342","2344~2459","2461~2493","2495~2527","2529~2566","2569~2570","2572~2577","2582~2584","2592~2592","2598~2598","2600~2605","2616~2616","2620~2625","2627~2684","2686~2707"],
                        [],
                        ["1630~1632","1637~1639","1642~1642","1645~1645","1738~1758","1850~1850","1860~1861","1892~1894","1896~1897","2042~2042","2105~2106","2108~2110","2113~2113","2196~2196","2201~2203","2590~2590"],
                        ["1172~1172","1178~1178","1180~1180","1619~1622","1640~1641","1643~1644","1646~1649","1781~1782","1784~1784","1811~1811","1852~1859","1898~1912","1914~1915","2034~2034","2037~2038","2040~2041","2043~2047","2049~2052","2058~2062","2067~2069","2184~2195","2222~2222","2225~2227","2229~2231","2234~2237","2239~2239","2248~2248","2276~2341","2343~2343","2460~2460","2567~2568","2571~2571","2578~2581","2585~2586","2588~2588","2591~2591","2593~2597","2607~2607","2617~2618","2626~2626","2685~2685","2708~2708"],
                        ],
                    nodeS:[[15.6],[7.4,51.3,83.3,101.9],[],[],[]
                        ],
                    nodeE:[[50.9],[15.5,70.3,88.3,106.9],[],[],[]
                        ],
                        countLoop:-1,
                        background:'',
                        showResult:false,
        }
    },
    computed:{
        randomColor(){
            var r = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            var g = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            var b = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            console.log("#"+r+g+b)
            return '#' + r + g + b;  
        }
    },
    methods:{
        generateRandomColor() {  
            var r = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            var g = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            var b = Math.floor(Math.random() * 126+130).toString(16).padStart(2, '0');  
            console.log("#"+r+g+b)
            return '#' + r + g + b;  
        },  
        computeStyle(position,index) { 
            if (index!=this.countLoop){
                this.countLoop=index
                this.background = this.generateRandomColor(); // 每次调用时都会生成新的颜色  
            }
        // 这里计算样式，包括 left 和 background  
        const left = `${position * 23 / 2}px`;  
        var background=this.background
        return { left,background };  
        },  
        analysising(){
            const loading=ElLoading.service({
                lock:true,
                text:"AI分析中……",
                background:'rgba(0,0,0,0.7)'
            })
           setTimeout(function(){this.showResult =true,loading.close()}.bind(this),2000)
        }
    }    
}
</script>

<style lang="scss" scoped>
.VideoPreview{
    
}
.VideoPreview_Header{
    
}
.VideoPreview_Header_Text{
    
}
.VideoPreview_Screen{
   
}
.VideoPreview_Screen_Player{
    width:320px;
   height:240px; 
}
.VideoPreview_OP{
    
}
.VideoPreview_Analysiser{
    margin-top:20px;
    background: rgba(240,240,240,0.25);    
}
.VideoPreview_Analysiser_Text{
    display: block;
    margin: 20px 0;
    font-size: 14pt;
    font-weight: 500;  
}
.VideoPreview_Analysiser_Row{
    display: flex;
    flex-direction: row; 
}
.VideoPreview_Analysiser_Column{
    flex: 1;  
}
.VideoPreview_Analysiser_Column_Title{
    display: block;
    margin-bottom: 20px;
}
.VideoPreview_Analysiser_Column_Snap{
    
}
.VideoPreview_Analysiser_Column_Snap_Title{
    display: block;
    color: #666;
}
.VideoPreview_Analysiser_Column_Snap_IMG{
    width: 160px;
    height: 90px;
}
.VideoPreview_Analysiser_Column_Snap_NDIMG{
    width: 160px;
    height: 160px;
}
.VideoPreview_Analysiser_Column_Params{
    
}
.VideoPreview_Analysiser_Column_frameInfo{
    font-size: 10pt;
    color:#666;
}.VideoPreview_Cut{
    
}
.VideoPreview_Cut_Time_Axis{
    background: rgba(222, 222, 222, 0.45);
    margin:20px 0;
     
}
.VideoPreview_Cut_Time_Axis_Header{
 display: flex;
 flex-direction: column;  
}
.VideoPreview_Cut_Time_Axis_Header_Title{
    display: block;
    font-size: 14pt;
    font-weight: 500;
}
.VideoPreview_Cut_Time_Axis_Header_Subtitle{
    display: block;
    font-size: 10pt;
    font-weight: 400;
    color:#fa9740; 
}
.VideoPreview_Cut_Time_Axis_Example{
    display:flex;
    flex-direction: row; 
 }
 .VideoPreview_Cut_Time_Axis_Mark_Container{
    display: inline-flex;
    width: 100%;
    margin:10px  30px 0 30px;
 }
.VideoPreview_Cut_Time_Axis_Mark{
  
}
.VideoPreview_Cut_Time_Axis_Mark_Start{
    height: 20px;
    width: 20px;
    border: 1px solid gray;
    font-weight: 800;
    font-size: 7pt;
    border-radius: 10px 10px 100px 100px;
    display: inline-block;
    position: absolute;
}
.VideoPreview_Cut_Time_Axis_Mark_End{
    height: 20px;
    font-size: 7pt;
    width: 20px;
    border: 1px solid gray;
    font-weight: 800;
    border-radius: 10px 10px 100px 100px;
    display: inline-block;
    position: absolute; 
}
.VideoPreview_Cut_Time_Axis_Mark_Example_Start {
    height: 20px;
    width: 10px;
    background: #faa54a;
    border: 1px solid gray;
    font-weight: 800;
    font-size: small;
    border-radius: 10px 10px 100px 100px;
    display: inline-block;
}
.VideoPreview_Cut_Time_Axis_Mark_Example_End
{
    height: 20px;
    font-size: small;
    width: 10px;
    background: #faa54a;
    border: 1px solid gray;
    font-weight: 800;
    border-radius: 10px 10px 100px 100px;
    display: inline-block;
}
.VideoPreview_Cut_Time_Axis_Axis{
    height: 10px;
    width: calc(2708 / 2);
    background: rgb(64, 175, 240);
    border: 1px solid gray;
    border-radius: 10px;
    margin: 0 30px; 
}
</style>
