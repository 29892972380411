<template>
    <div class="container">
      <div class="setup">
        <span class="title">注意事项</span>
        <span class="memo">- 请在准备好之后点击开始考试</span>
        <span class="memo">- 考试时间为60分钟，考试结束后请按退出考试按钮退出考试</span>
        <span class="memo">- 听力部分每道题会播放2遍</span>
        <span class="memo">- 考试结束后会展示考试成绩</span>
      </div>
      
      <div class="ready">
        <el-button v-if="!testStarting" class="ready_rest_time_startbutton" type="primary" @click="startTesting">开始考试</el-button>
        <el-button v-else class="ready_rest_time_startbutton" type="primary" disabled>开始考试</el-button>
      </div>

        <!-- 阅读 -->
        <div v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Reading</span>
          <!-- 1~6 题 -->
            <span class="test_paper_type_name">Questions 1~6 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  
            </span>
            <el-divider></el-divider>
            <div class="reading_part_1_group" v-for="item in RP1" :key="item.id">
              <div class="reading_part_1_group_text">{{item.id+". "+ item.text }}
              </div>
              <div class="reading_part_1_group_stem_options">
                  <div class="reading_part_1_group_stem">{{item.id+". "+item.stem}}</div>
                  <div class="reading_part_1_group_options" 
                    v-for="o in item.options" 
                    :key="o.id"
                  
                     @click="set_RP1_style(3*(item.id-1)+o.id-1)" >
                    <span :class="reading_part1_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                  </div>
              </div>
              
            </div>

          <!-- 7~13 题 -->
          <span class="test_paper_type_name">Questions 7~13 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  
            </span>
            <el-divider></el-divider>

            <div class="reading_part_2_group_title">{{RP2.title}}</div>
           
            <div class="reading_part_2_group">
              <div class="reading_part_2_group_text">
                  <div class="reading_part_2_group_text_item" v-for="r2 in RP2.texts" :key="r2.id">
                    <div class="reading_part_2_group_text_item_name">{{r2.name}}</div>
                    <div class="reading_part_2_group_text_item_text">{{r2.text}}</div>
                  </div>
              </div>
              
              <div class="reading_part_2_group_stem_options">
                <div class="reading_part_2_group_names">
                  <div class="reading_part_2_group_names_row">
                    <span class="reading_part_2_group_names_row_tag" v-for="name in RP2.texts" :key="name.id">{{name.name}}</span>
                  </div>
                </div>
                
                <div>
                  <div class="reading_part_2_group_stem_options_row"
                    v-for="item in RP2.questions" :key="item.id"
                  >
                    <div class="reading_part_2_group_stem_options_row_stem">{{item.id+". "+item.stem}}</div>
                    <div class="reading_part_2_group_stem_options_row_option">
                      <span :class="reading_part2_options[3*(item.id-1)+option.id-1]" 
                      @click="set_RP2_style(3*(item.id-1)+option.id-1)"
                      v-for="option in item.options" :key="option.id">{{option.option}}</span></div> 
                  </div>
                </div>
              </div>
              
            </div>

          <!-- 14~18 题 -->
          <span class="test_paper_type_name">Questions 14~18 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <el-divider></el-divider>

          <div class="reading_part_3_group">
            <div class="reading_part_3_group_text_group">
              <div class="reading_part_3_group_text_group_title">{{RP3.title }}
              </div>
              <div class="reading_part_3_group_text_group_text">{{RP3.text }}
              </div>
            </div>
           
            <div class="reading_part_3_group_stem_options_group">
              <div class="reading_part_3_group_stem_options" v-for="item in RP3.questions" :key="item.id">
                <div class="reading_part_3_group_stem">{{item.id+". "+item.stem}}</div>
                <div class="reading_part_3_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                  @click="set_RP3_style(3*(item.id-1)+o.id-1)"
                  >
                  <span :class="reading_part3_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            </div>
          </div> 

          <!-- 19~24 题 -->
          <span class="test_paper_type_name">Questions 19~24 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <el-divider></el-divider>

          <div class="reading_part_4_group">
            <div class="reading_part_4_group_text_group">
              <div class="reading_part_4_group_text_group_title">{{RP4.title }}
              </div>
              <div class="reading_part_4_group_text_group_text">{{RP4.text }}
              </div>
            </div>
            
            <div class="reading_part_4_group_stem_options_group">
              <div class="reading_part_4_group_stem_options" v-for="item in RP4.questions" :key="item.id">
                <div class="reading_part_4_group_stem">{{item.id+". "+item.stem}}</div>
                <div class="reading_part_4_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                  @click="set_RP4_style(3*(item.id-1)+o.id-1)"
                  >
                  <span :class="reading_part4_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            </div>
          </div> 

        </div>

        <!-- 写作 -->
        <div v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Writing</span>
          <!-- 25~30 题 -->
            <span class="test_paper_type_name">Questions 25~30 </span>
            <span class="test_paper_type_instruction">
              For each question, choose the correct answer.  Write one word for each gap.
            </span>
            <span class="test_paper_example">
              Example: <span style="margin-left:10px;font-weight:600;text-align:center; width:30px;height:20px; border:solid 1px #000;font-size:10pt">0</span><span  style="font-weight:600;text-align:center;width:30px;height:20px; font-style:italic; border:solid 1px #000;font-size:10pt">a</span>
            </span>
            <el-divider></el-divider>
            <div class="writing_part_5_group">
              <div class="writing_part_5_group_text">{{WP1.text }}
              </div>
              <div class="writing_part_5_group_blanks"
                    v-for="o in 6" 
                    :key="o">
                    {{ "("+(o+24)+")." }}
                    <el-input style="width:240px" 
                    :placeholder="'Fill in ('+(o+24)+') anwser'"
                    v-model="WP1_Filling[o-1]"></el-input>
              </div>
            </div>

          <!-- 31 题 -->
          <el-divider></el-divider>
          <span class="test_paper_type_name">Questions 31 </span>
          <div class="writing_part_6_group">
            <div class="writing_part_6_group_text">
              <span class="writing_part_6_group_stem">{{WP2.stem }}</span>
              <span class="writing_part_6_group_require">{{WP2.require }}</span>
              <span class="writing_part_6_group_notic">{{WP2.notic }}</span>
            </div>
            <div class="writing_part_6_group_blanks">
                 
                  <el-input style="width:480px;" rows=10 
                  type="textarea"
                  placeholder="Write here"
                  v-model="WP2_Filling"></el-input>
            </div>
          </div>  


        <!-- 32 题 -->
        <el-divider></el-divider>
        <span class="test_paper_type_name">Questions 32 </span>
        <div class="writing_part_7_group">
          <div class="writing_part_7_group_text">
            <span class="writing_part_7_group_stem">{{WP3.stem }}</span>
            <span class="writing_part_7_group_require">{{WP3.require }}</span>
           
          </div>
          <div class="writing_part_7_group_imgs"><img class="writing_part_7_group_image"  v-for="i in WP3.imgs" :key="i.index" :src="i.url"></div>
          <span class="writing_part_7_group_notic">{{WP3.notic }}</span>
          <div class="writing_part_7_group_blanks">
               
                <el-input style="width:480px;" rows=10 
                type="textarea"
                placeholder="Write here"
                v-model="WP3_Filling"></el-input>
          </div>
        </div>  
      </div>

        <!-- 听力 1~5-->
        <div v-if="testStarting" class="test_paper">
          <span class="test_paper_part_name">Listening</span>
          <span class="test_paper_type_name">Questions 1~5 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>
          <el-divider></el-divider>
          <div class="listening_part_select_img_group" v-for="item in LPS" :key="item.id">
            <div class="listening_part_select_img_stem">
              <el-icon class="listening_part_select_img_stem_playbtn"><Headset /></el-icon><span class="listening_part_select_img_stem_playbtn">Play</span>
              {{item.id+". "+item.stem}}
            </div>
            
            <div class="listening_part_select_img_options">
              <div class="listening_part_select_img_quiz" @click="set_LPSI_style(3*(item.id-1)+option.id-1)"  v-for="option in item.options" :key="option.id"> 
              
                <img :class="listening_part_select_img_quiz_pic[3*(item.id-1)+option.id-1]" :src="option.img"/>
                <span :class="listening_part_select_img_quiz_option[3*(item.id-1)+option.id-1]">{{option.option}}</span>
              
              </div> 
            </div>
            
          </div>
          
           <!-- 6~10 题 -->
           <span class="test_paper_type_name">Questions 6~10 </span>
           <span class="test_paper_type_instruction">
             For each question, write the correct answer in the gap. Write one word or a number or a date or a time.  
           </span>
           <el-divider></el-divider>
          
           <div class="listening_part_select_img_stem">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset /></el-icon><span class="listening_part_select_img_stem_playbtn">Play</span>
            {{LP2.tips}}
          </div>

           <div class="listening_part_2_group">
            <div class="listening_part_2_group_text_group">
              <div class="listening_part_2_group_text_group_title">{{LP2.title }}
              </div>
              <div v-for="q in LP2.quizs" :key="q.id" class="listening_part_2_group_text_group_quizs">
                <div class="listening_part_2_group_text_group_quizs_stem">{{q.id+4+". "+q.stem }}</div>
                <div class="listening_part_2_group_text_group_quizs_blank">
                  {{q.blank }}
                  <el-input 
                  v-model="LP2_Blanks[key-1]" 
                  v-if="q.id!=1" :placeholder="q.id+4" 
                  style="width:240px;margin-left:10px"></el-input>
                </div>
              </div>
            </div> 
          </div>

         <!-- 11~15 题 -->
         <span class="test_paper_type_name">Questions 11~15 </span>
         <span class="test_paper_type_instruction">
           For each question,choose the correct answer.  
         </span>
         <el-divider></el-divider>
        
          <div class="listening_part_select_img_stem">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset /></el-icon><span class="listening_part_select_img_stem_playbtn">Play</span>
            {{LP3.tips}}
          </div>

          <div class="reading_part_1_group" v-for="item in LP3.quizs" :key="item.id">

            <div class="listening_part_3_group_stem_options">
                <div class="listening_part_3_group_stem">{{item.id+10+". "+item.stem}}</div>
                <div class="listening_part_3_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                   @click="set_LP3_style(3*(item.id-1)+o.id-1)" >
                  <span :class="listening_part3_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            
          </div>
          

        <!-- 16~20 题 -->
         <span class="test_paper_type_name">Questions 16~20 </span>
         <span class="test_paper_type_instruction">
           For each question,choose the correct answer.  
         </span>
         <el-divider></el-divider>
        
          
          <div class="reading_part_1_group" v-for="item in LP4.quizs" :key="item.id">

            <div class="listening_part_4_group_stem_options">
                <div class="listening_part_4_group_stem">
                  <el-icon class="listening_part_select_img_stem_playbtn"><Headset /></el-icon><span class="listening_part_select_img_stem_playbtn">Play</span>
                  {{item.id+15+". "+item.stem}}
                </div>
                <div class="listening_part_4_group_options" 
                  v-for="o in item.options" 
                  :key="o.id"
                   @click="set_LP4_style(3*(item.id-1)+o.id-1)" >
                  <span :class="listening_part4_options[3*(item.id-1)+o.id-1]">{{o.option+". "+o.answer}}</span>
                </div>
            </div>
            
          </div>

        <!-- 21~25 题 -->


          <span class="test_paper_type_name">Questions 21~25 </span>
          <span class="test_paper_type_instruction">
            For each question, choose the correct answer.  
          </span>

          <div class="listening_part_5_group_stem">
            <el-icon class="listening_part_select_img_stem_playbtn"><Headset /></el-icon><span class="listening_part_select_img_stem_playbtn">Play</span>
            {{LP5.tips}}
            <span class="test_paper_example">
              Example: <span  style="font-weight:500;text-align:center;font-size:11pt;margin-right:20px">0 Anthony</span><span  style="font-weight:600;text-align:center;width:30px;height:20px; font-style:italic; border:solid 1px #000;font-size:10pt">B</span>
            </span>
            <el-divider></el-divider>
          </div>
         
          <b>You now have six mintues to write your answers on the answer sheet.</b>
        <div class="listening_part5_group">
          <div class="listening_part5_group_blanks">
           
           <span class="listening_part5_group_blanks_text" 
           v-for="l5b in LP5.texts" 
           :key="l5b.id"
          >{{ "("+(l5b.id+20)+"). "+l5b.text }}
          <el-input style="width:240px;margin-left:5px" 
          :placeholder="'Fill in ('+(l5b.id+20)+') anwser'"
          v-model="LP5_Filling[l5b.id-1]"></el-input>
        </span>
         
                
          </div>
        
          <div class="listening_part5_group_options"
                >
               <div v-for="o in LP5.options" 
               :key="o.id"
               class="listening_part5_group_options_item">
                
                  {{ o.option+"."+o.answer }}
                 
                 
              </div>
          </div>
        </div>
      </div>   


      <!-- 口语 -->
      <div v-if="testStarting" class="test_paper">
        <span class="test_paper_part_name">Speaking</span>
       
          <span class="test_paper_type_name">{{SP1.stem}}</span>
          <span class="test_paper_type_instruction">
            You will as role B to answer the following questions.  
          </span>
          <el-divider></el-divider>

          <div class="listening_part5_group">
            <div class="spoken_group_frame"> 
                <div class="listening_part_select_img_group" v-for="item in SP1.imgs" :key="item.id">
                  <img class="writing_part_7_group_image" :src="item.url"/>
                </div>  
            </div>
          
            <div class="spoken_answer_group">
              <el-scrollbar height="300px">
                  <div class="spoken_answer_group_talk"><span style="color:#409eff;font-weight:500">officer said:</span><span> {{SP1.officer}}</span></div>
                  <div v-for="item in SP_Conversation" :key="item.id">
                    <div class="spoken_answer_group_talk">
                      <span style="color:#00c297;font-weight:500">A said:</span><span> {{item.a}}</span> 
                    </div>   
                    <div class="spoken_answer_group_talk">
                      <span style="color:#6016fa;font-weight:500">You said:</span><span> {{item.you}}</span>
                    </div>                 
                  </div>
              </el-scrollbar>
              <div class="spoken_button"> <el-button type="primary" :icon="Microphone" >Speaking</el-button></div>  
            </div>
          </div>
      </div>

      <div class="footer">
        <div class="ready_rest_time">剩余时间:<span class="ready_rest_time_countdown">{{restTime}}</span></div>
        <div class="rest_question"><span class="rest_question_num" >答题数量：</span><span class="rest_question_num_yet">{{answerYet}}</span>/<span class="rest_question_num_total">59</span></div>
        <div>
          <el-button v-if="testStarting" class="ready_rest_time_startbutton" type="primary" @click="endTesting">交卷</el-button>
         <el-button v-else class="ready_rest_time_startbutton" type="primary" disabled>交卷</el-button>    
        </div> 
      </div>
    </div>   
</template>
   
  <script>
   
    import rp1Data from "@/assets/data/reading_part_1_data.json"
    import rp2Data from "@/assets/data/reading_part_2_data.json"
    import rp3Data from "@/assets/data/reading_part_3_data.json"
    import rp4Data from "@/assets/data/reading_part_4_data.json"

    import wp1Data from "@/assets/data/writing_part_1_data.json"
    import wp2Data from "@/assets/data/writing_part_2_data.json"
    import wp3Data from "@/assets/data/writing_part_3_data.json"

    import lp1Data from "@/assets/data/listening_part_1_data.json"
    import lp2Data from "@/assets/data/listening_part_2_data.json"
    import lp3Data from "@/assets/data/listening_part_3_data.json"
    import lp4Data from "@/assets/data/listening_part_4_data.json"
    import lp5Data from "@/assets/data/listening_part_5_data.json"

    import sp1Data from "@/assets/data/speaking_part_data.json"

    export default {
        name: 'AITesting',
        components:{
           
        },
        data(){
            return{
               restTime:"--:--",
               testStarting:true,// 调试之后改回来
               answers:[],// 答案
               RP1:[],// 阅读1~6题
               RP2:[],// 阅读7~13题
               RP3:[],// 阅读14~18题
               RP4:[],// 阅读19~24题
               reading_part1_options:[], // 阅读第一部分选项样式
               reading_part2_options:[], // 阅读第二部分选项样式
               reading_part3_options:[], // 阅读第三部分选项样式
               reading_part4_options:[], // 阅读第四部分选项样式

               WP1:{},// 写作题第一部分
               WP1_Filling:[],// 写作题第一部分填空题答案
               
               WP2:{},// 写作题第二部分
               WP2_Filling:null,// 写作题第二部分填空题答案

               WP3:{},// 写作题第三部分
               WP3_Filling:null,// 写作题第三部分填空题答案

               LPS:[],// 听力1选图题
               listening_part_select_img_quiz_pic:[], // 听力选图题图片样式
               listening_part_select_img_quiz_option:[], // 听力选图题选项样式

               LP2:{},// 听力2部分
               LP2_Blanks:[],// 听力2部分填空题答案

               LP3:{},// 听力3部分
               listening_part3_options:[], // 听力第三部分选项样式

               LP4:{},// 听力4部分
               listening_part4_options:[], // 听力第四部分选项样式

               LP5:{},// 听力5部分
               LP5_Filling:[], // 听力第五部分选填空

               SP1:{},//口语部分第一题
               SP1_Audio:[],
               SP_Conversation:[],// 口语对话
            }
        },
        computed:{
          answerYet(){
            return this.answers.length
          }
        },
        mounted(){
          this.RP1=rp1Data.RP1
          console.log("checking RP1:",this.RP1)
          this.RP1.forEach((element)=>{
              element.options.forEach((option)=>{
                  console.log(option) 
                  this.reading_part1_options.push("reading_part_1_options") // 阅读第第一部分选项样式
              })
            })

          this.RP2=rp2Data
          this.RP2.questions.forEach((element)=>{
              element.options.forEach((option)=>{
                  console.log(option) 
                  this.reading_part2_options.push("reading_part_2_group_stem_options_row_option_tag") // 阅读第第二部分选项样式
              })
            })

          this.RP3=rp3Data
          this.RP3.questions.forEach((element)=>{
              element.options.forEach((option)=>{
                  console.log(option) 
                  this.reading_part3_options.push("reading_part_3_group_options") // 阅读第第三部分选项样式
              })
            })

          this.RP4=rp4Data
          this.RP4.questions.forEach((element)=>{
              element.options.forEach((option)=>{
                  console.log(option) 
                  this.reading_part4_options.push("reading_part_4_group_options") // 阅读第第四部分选项样式
              })
            })  

          this.WP1=wp1Data
          this.WP2=wp2Data
          this.WP3=wp3Data

          this.LPS=lp1Data.LP1
          this.LPS.forEach((element)=>{
              element.options.forEach((option)=>{
                  console.log(option)
                  this.listening_part_select_img_quiz_pic.push("listening_part_select_img_quiz_pic"), // 听力选图题图片样式
                  this.listening_part_select_img_quiz_option.push("listening_part_select_img_quiz_option") 
                  console.log(this.listening_part_select_img_quiz_pic) 
                  console.log(this.listening_part_select_img_quiz_option) 
              })
            })

            this.LP2=lp2Data
            this.LP3=lp3Data
            this.LP4=lp4Data
            this.LP5=lp5Data

            this.SP1=sp1Data
            this.SP_Conversation=[{
              "id":1,
              "officer":"go on",
              "a":"I prefer to take public transportation, which is low-carbon and environmentally friendly, and the cost is very low. The most important thing is that the bus is very punctual. What method do you prefer.",
              "you":"Don't ask me if I don't understand."
            }]
        },
        methods: {
          startCountDown(){
            this.restTime="59:59"
            let strtime=this.restTime.split(":")
            let time=[parseInt(strtime[0]),parseInt(strtime[1])]
            setInterval(()=>{
              if(time[1]==0&&time[0]==0){
                clearInterval()
                this.restTime="--:--"
              }else{
                if(time[1]!=0){
                  time[1]-=1
                }else{
                  time[1]=59
                  time[0]-=1
                }
                this.restTime= time[0].toString().padStart(2,'0')+":"+time[1].toString().padStart(2,'0')
              }
             
            },1000)
          },
          startTesting(){
            this.testStarting=true
            this.startCountDown()
          },
          
          // setSingleOptionStyle(target,normal,selected,key){
          //   // 阅读第一部分题目样式选择
          //   console.log("set_target:",target) 
          //   console.log("set_normal:",normal) 
          //   console.log("set_selected:",selected) 
          //   console.log("set_target_key:",key) 
          //   // 把同一道题的选项复位
          //   let rests=Math.floor(key/3)
          //   console.log("rests:",rests) 
          //   for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
          //     console.log("clean set j:",j) 
          //     target[j]=normal
          //   }
          //   // 选中新值
          //  target[key]=selected

          // },
          set_RP1_style(key){
            // 阅读第一部分题目样式选择
            console.log("set_RP1_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part1_options[j]="reading_part_1_options"
            }
            // 选中新值
           this.reading_part1_options[key]=("reading_part_1_options_selected") 
          },
          set_RP2_style(key){
            // 阅读第2部分题目样式选择
            console.log("set_RP2_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part2_options[j]="reading_part_2_group_stem_options_row_option_tag"
            }
            // 选中新值
           this.reading_part2_options[key]=("reading_part_2_group_stem_options_row_option_tag_selected") 
          },
          set_RP3_style(key){
            // 阅读第3部分题目样式选择
            console.log("set_RP3_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part3_options[j]="reading_part_3_group_options"
            }
            // 选中新值
           this.reading_part3_options[key]=("reading_part_3_group_options_selected") 
          },
          set_RP4_style(key){
            // 阅读第4部分题目样式选择
            console.log("set_RP4_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.reading_part4_options[j]="reading_part_4_group_options"
            }
            // 选中新值
           this.reading_part4_options[key]=("reading_part_4_group_options_selected") 
          },  

          set_LPSI_style(key){
            // 听力选图题的样式选择
            // 听力选图题图片样式

            console.log("set_LPSI_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.listening_part_select_img_quiz_pic[j]="listening_part_select_img_quiz_pic"
              this.listening_part_select_img_quiz_option[j]="listening_part_select_img_quiz_option"
            }
            // 选中新值
            this.listening_part_select_img_quiz_pic[key]=("listening_part_select_img_quiz_pic_selected")
            this.listening_part_select_img_quiz_option[key]=("listening_part_select_img_quiz_option_selected")
          },
          set_LP3_style(key){
            // 阅读第3部分题目样式选择
            console.log("set_LP3_style:",key) 
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests) 
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j) 
              this.listening_part3_options[j]="listening_part_3_group_options"
            }
            // 选中新值
           this.listening_part3_options[key]=("listening_part_3_group_options_selected") 
          },
          set_LP4_style(key){
            // 阅读第4部分题目样式选择
            console.log("set LP4 style:",key)
            // 把同一道题的选项复位
            let rests=Math.floor(key/3)
            console.log("rests:",rests)
            for(let j=rests+2*rests;j<=rests+2*rests+2;j++){
              console.log("clean set j:",j)
              this.listening_part4_options[j]=("listening_part_4_group_options")
            }
          },
          endTesting(){
            // 交卷
            this.$router.push('/testing-report')
          }
    },
  }
  </script>
   
<style scoped>
div#app{
  display: flex;
  justify-content: center;
}
.container{
  display: flex;
  margin-left:auto;
  margin-right: auto;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
  flex-direction: column;
  max-width:1200px;
}

.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }
  .title{
    display: flex;
    margin:30px 20px 10px 20px;
    font-size: 15pt;
    font-weight: 600;
    font-size: 14pt;
    color:#7d8084;
    }
    .memo{
    display: flex;
    margin:10px 20px 3px 40px;
    font-size: 10pt;
    font-weight: 400;
    color:#979a9f;
    }
    .memo:last-child{
    margin-bottom: 30px;
    }

.ready{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content:left;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }

.ready_rest_time{
  margin-top:20px;
  min-width: 200px;
}

.ready_rest_time_countdown{
  color:#409eff;
  font-size: 20pt;
  font-weight: 500;
  margin-left: 10px;
}
.ready_rest_time_startbutton{
  margin-top:20px;
  margin-bottom: 20px;
  margin-left: 40px;
}

.footer{
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  
  justify-content:space-around;
  background: #fff;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.rest_question{
  display: flex;
  margin-top:30px;
  flex-direction: row;
}
.rest_question_num{
  display:block;
}
.rest_question_num_total{
  font-weight: 500; 
}
.rest_question_num_yet{
  font-weight: 550;
  color:#409eff;
}

@media screen and (max-width: 1024px) {
  .ready{
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content:left;
    background: #fff;
    width: 100%;
    border: 1px solid rgba(68,74,98,0.15);
    border-radius: 2px;
    box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
    }
  
  .ready_rest_time{
    margin-top:20px;
    margin-left:20px;
    min-width: 100px;
    text-align: left;
  }
  
  .ready_rest_time_countdown{
    color:#409eff;
    font-size: 20pt;
    font-weight: 500;
    margin-left: 10px;
  }
  .ready_rest_time_startbutton{
    margin-top:20px;
    margin-bottom: 20px;
    margin-left: 40px;
  }  

.footer{
  position: fixed;
  bottom: 0;
  left:0;
  width: 100%;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  
  justify-content:space-around;
  background: #fff;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.rest_question{
  display: flex;
  margin-top:30px;
  margin-left:20px;
  flex-direction: row;
}
.rest_question_num{
  display:block;
}
.rest_question_num_total{
  font-weight: 500; 
}
.rest_question_num_yet{
  font-weight: 550;
  color:#409eff;
}
}

.test_paper{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:left;
  background: #fff;
  width: 100%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}

.test_paper_part_name{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  color:#000; 
}
.test_paper_type_name{
  display: flex;
  margin:20px 40px;
  font-size: 13pt;
  font-weight: 500;
  color:#000;  
}
.test_paper_type_instruction{
  display: flex;
  text-align: left;
  margin: -10px 40px ;
  font-size: 12pt;
  font-weight: 450;
  color:#409eff;   
}
.test_paper_example{
  display: flex;
  text-align: left;
  margin: 20px 40px ;
  font-size: 12pt;
  font-weight: 450; 
}



.reading_part_1_group{
  display: flex;
  flex-direction: row;
  margin-bottom:20px;
}

.reading_part_1_group_text{
  text-align: left;
  font-weight: 600;
  width: 40%;
  margin:0 40px;
  font-size: 12pt;
  white-space: pre-wrap; /* 换行 */
  padding-right: 15px;
  border-right: 1px solid rgba(113, 113, 114, 0.15);
}
.reading_part_1_group_stem_options{
  display: flex;
  flex-direction: column;
}
.reading_part_1_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px;
}
.reading_part_1_group_options{
  margin-bottom: 20px;
  text-align: left;
  cursor:pointer;
}
.reading_part_1_options{
  font-size: 12pt;
  font-weight: 450;
}
.reading_part_1_options_selected{
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

@media screen and (max-width: 1024px) {
  .reading_part_1_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
  } 
  .reading_part_1_group_text{
    text-align: left;
    font-weight: 600;
    width: 80%;
    margin:0 0 20px 40px;
    font-size: 12pt;
    white-space: pre-wrap; /* 换行 */
    padding-right: 15px;
    border-right: 0px;
    }
  .reading_part_1_group_stem_options{
    display: flex;
    flex-direction: column;
    margin-left:40px;
  }
  .reading_part_1_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    width:80%;
    margin-bottom: 10px;
  }
  .reading_part_1_group_options{
    margin-bottom: 20px;
    text-align: left;
    cursor:pointer;
    width:80%;
  }
  .reading_part_1_options{
    font-size: 12pt;
    font-weight: 450;
  }
  .reading_part_1_options_selected{
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }
}

.reading_part_2_group_title{
  display: flex;
  justify-content: left;
  font-size:15pt;
  font-weight: 550;
  padding:0 40px;
  margin-bottom: 40px;
}
.reading_part_2_group{
  display: flex;
  flex-direction: row;
  margin-bottom:20px; 
}
.reading_part_2_group_text{
  margin:20px 40px;
  width:50%;
  padding-right:20px;
  border-right: 1px solid rgba(113, 113, 114, 0.15); 
}
.reading_part_2_group_text_item{
  text-align: left;
  margin-bottom: 20px;
}
.reading_part_2_group_text_item_name{
  font-size: 12pt;
  font-weight: 550;
  margin-bottom: 10px;
}
.reading_part_2_group_text_item_text{
  font-size: 12pt;
  font-weight: 450;
  margin-bottom: 10px;
}
.reading_part_2_group_stem_options{
  display: flex;
  flex-direction: column;
}
.reading_part_2_group_names{
  display:flex;
  flex-direction:row;
}
.reading_part_2_group_names_row{
  margin-left:60%;
}
.reading_part_2_group_names_row_tag{
  display: inline-block;
  width:33.3%;
  font-weight:550;
}
.reading_part_2_stem{
  display: flex;
  flex-direction: row;
}
.reading_part_2_stem_group{
  display: flex;
  flex-direction: column;
}
.reading_part_2_group_stem_options_row{
  display:flex;
  flex-direction:row
}
.reading_part_2_group_stem_options_row_stem{
  width: 60%;
  text-align: left;
  margin:20px 20px 20px 0;
  font-weight: 500;
}
.reading_part_2_group_stem_options_row_option{
  text-align: center;
  width:40%;
  margin:20px  0; 
  cursor:pointer;
}
.reading_part_2_group_stem_options_row_option_tag{
  display:inline-block;
  width:30px;
  height:25px;
  margin-right: 35px;
  text-align: center;
  padding-top: 5px;
  background: rgba(113, 113, 114, 0.15);
  border: 1px solid rgba(113, 113, 114, 0.15);
  border-radius: 80px;
 }

 .reading_part_2_group_stem_options_row_option_tag_selected{
  display:inline-block;
  width:30px;
  height:25px;
  margin-right: 35px;
  text-align: center;
  padding-top: 5px;
  background: #409eff;
  border: 1px solid #409eff;
  color: #fff;
  border-radius: 80px;
 }

 @media screen and (max-width: 1024px){
  .reading_part_2_group_title{
    display: flex;
    justify-content: left;
    font-size:15pt;
    font-weight: 550;
    padding:0 40px;
    margin-bottom: 40px;
  }
  .reading_part_2_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px; 
  }
  .reading_part_2_group_text{
    margin:20px 40px;
    width:80%;
    padding-right:20px;
    border-right: 0px ; 
  }
  .reading_part_2_group_text_item{
    text-align: left;
    margin-bottom: 20px;
  }
  .reading_part_2_group_text_item_name{
    font-size: 12pt;
    font-weight: 550;
    margin-bottom: 10px;
  }
  .reading_part_2_group_text_item_text{
    font-size: 12pt;
    font-weight: 450;
    margin-bottom: 10px;
  }
  .reading_part_2_group_stem_options{
    display: flex;
    flex-direction: column;
    width:80%;
    margin-left:40px;
  }
  .reading_part_2_group_names{
    display:flex;
    flex-direction:row;
  }
  .reading_part_2_group_names_row{
    margin-left:60%;
  }
  .reading_part_2_group_names_row_tag{
    display: inline-block;
    width:33.3%;
    font-size:10pt;
    font-weight:550;
  }
  .reading_part_2_stem{
    display: flex;
    flex-direction: row;
  }
  .reading_part_2_stem_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_2_group_stem_options_row{
    display:flex;
    flex-direction:row
  }
  .reading_part_2_group_stem_options_row_stem{
    width: 60%;
    text-align: left;
    margin:20px 20px 20px 0;
    font-weight: 500;
  }
  .reading_part_2_group_stem_options_row_option{
    text-align: center;
    width:40%;
    margin:20px  0; 
    cursor:pointer;
  }
  .reading_part_2_group_stem_options_row_option_tag{
    display:inline-block;
    font-size:10pt;
    width:20px;
    height:20px;
    margin-right: 10px;
    text-align: center;
    padding-top: 2px;
    background: rgba(113, 113, 114, 0.15);
    border: 1px solid rgba(113, 113, 114, 0.15);
    border-radius: 20px;
   }
  
   .reading_part_2_group_stem_options_row_option_tag_selected{
    display:inline-block;
    font-size: 10pt;
    width:20px;
    height:20px;
    margin-right: 10px;
    text-align: center;
    padding-top: 2px;
    background: #409eff;
    border: 1px solid #409eff;
    color: #fff;
    border-radius: 20px;
   }
 }

.reading_part_3_group{
  display: flex;
  flex-direction: row;
}
.reading_part_3_group_text_group{
  display: flex;
  flex-direction: column;
  width:50%;
  margin:20px;
  text-align: left;
}
.reading_part_3_group_text_group_title{
  font-size: 14pt;
  font-weight: 550;
  text-align: center;
  margin-bottom: 20px;  
}
.reading_part_3_group_text_group_text{
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
}
.reading_part_3_group_stem_options_group{
  display: flex;
  flex-direction: column; 
  text-align: left;
  margin:40px 0;
}
.reading_part_3_group_stem_options{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.reading_part_3_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px; 
}
.reading_part_3_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 10px; 
  cursor: pointer;
}
.reading_part_3_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 10px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

@media screen and (max-width: 1024px){
  .reading_part_3_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_3_group_text_group{
    display: flex;
    flex-direction: column;
    width:80%;
    margin:20px;
    text-align: left;
  }
  .reading_part_3_group_text_group_title{
    font-size: 14pt;
    font-weight: 550;
    text-align: center;
    margin-bottom: 20px;  
  }
  .reading_part_3_group_text_group_text{
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
  }
  .reading_part_3_group_stem_options_group{
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin:40px 20px;
  }
  .reading_part_3_group_stem_options{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width:80%;
  }
  .reading_part_3_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    margin-bottom: 10px; 
  }
  .reading_part_3_group_options{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 10px; 
    cursor: pointer;
  }
  .reading_part_3_group_options_selected{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 10px; 
    cursor: pointer;
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }
}

.reading_part_4_group{
  display: flex;
  flex-direction: row;
}
.reading_part_4_group_text_group{
  display: flex;
  flex-direction: column;
  width:50%;
  margin:20px;
  text-align: left;
}
.reading_part_4_group_text_group_title{
  font-size: 14pt;
  font-weight: 550;
  text-align: center;
  margin-bottom: 20px;  
}
.reading_part_4_group_text_group_text{
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
}
.reading_part_4_group_stem_options_group{
  display: flex;
  flex-direction: column; 
  text-align: left;
  margin:40px 0;
}
.reading_part_4_group_stem_options{
  display: flex;
  /*flex-direction: column;*/
  align-items: left;
  margin-bottom: 20px;
}
.reading_part_4_group_stem{
  display: block;
  text-align: left;
  font-size: 12pt;
  font-weight: 600;
  margin-bottom: 10px; 
}
.reading_part_4_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:0 20px 10px 10px; 
  cursor: pointer;
}
.reading_part_4_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 10px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}

@media screen and (max-width: 1024px){
  .reading_part_4_group{
    display: flex;
    flex-direction: column;
  }
  .reading_part_4_group_text_group{
    display: flex;
    flex-direction: column;
    width:80%;
    margin:20px;
    text-align: left;
  }
  .reading_part_4_group_text_group_title{
    font-size: 14pt;
    font-weight: 550;
    text-align: center;
    margin-bottom: 20px;  
  }
  .reading_part_4_group_text_group_text{
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
  }
  .reading_part_4_group_stem_options_group{
    display: flex;
    flex-direction: column; 
    text-align: left;
    margin:40px 20px;
    width:80%;
  }
  .reading_part_4_group_stem_options{
    display: flex;
    /*flex-direction: column;*/
    align-items: left;
    margin-bottom: 20px;
  }
  .reading_part_4_group_stem{
    display: block;
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    margin-bottom: 10px; 
  }
  .reading_part_4_group_options{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin:0 20px 10px 10px; 
    cursor: pointer;
  }
  .reading_part_4_group_options_selected{
    display: block;
    text-align: left;
    font-size: 12pt;
    margin-bottom: 10px; 
    cursor: pointer;
    font-size: 12pt;
    font-weight: 650;
    background:#409eff;
    color:#fff;
  }
}

.writing_part_5_group{
  display: flex; 
  flex-direction: column;
}
.writing_part_5_group_text{
  margin: 0 40px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left; 
}
.writing_part_5_group_blanks{
  display: flex;
  flex-direction: row;
  margin:  20px 40px;
}
.writing_part_6_group{
  display: flex; 
  flex-direction: column; 
}
.writing_part_6_group_text{
  display:block;
  margin: 0 40px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_stem{
  display:block;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_require{
  display:block;
  margin: 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_6_group_notic{
  display:block;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
  color:#409eff;
  font-weight: 600;
}
.writing_part_6_group_blanks{
  display: flex;
  justify-items: left;
  margin: 20px 40px;
}

.writing_part_7_group{
  display: flex; 
  flex-direction: column;  
}
.writing_part_7_group_imgs{
  display: flex;
  flex-direction: column;
  margin: 0 40px;
}
.writing_part_7_group_image{
  border: 1px solid #000; 
  margin:0 40px;
}
.writing_part_7_group_text{
  display:block;
  margin: 0 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_stem{
  display:block;
  line-height: 22pt;
  margin: 0 20px;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_require{
  display:block;
  margin: 20px;
  line-height: 22pt;
  white-space: pre-wrap;  
  text-align: left;  
}
.writing_part_7_group_notic{
  display:block;
  line-height: 22pt;
  margin:20px 40px 0 40px;
  white-space: pre-wrap;  
  text-align: left;  
  color:#409eff;
  font-weight: 600;
}
.writing_part_7_group_blanks{
  display: flex;
  justify-items: left;
  margin: 20px 40px;
}

@media screen and (max-width: 1024px) {
  .writing_part_7_group{
    display: flex; 
    flex-direction: column;  
  }
  .writing_part_7_group_imgs{
    display: flex;
    flex-direction: column;
    margin: 20px 20px;
  }
  .writing_part_7_group_image{
    border: 1px solid #000; 
    margin:20px 20px;
  }
  .writing_part_7_group_text{
    display:block;
    margin: 0 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_stem{
    display:block;
    line-height: 22pt;
    margin: 0 20px;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_require{
    display:block;
    margin: 20px;
    line-height: 22pt;
    white-space: pre-wrap;  
    text-align: left;  
  }
  .writing_part_7_group_notic{
    display:block;
    line-height: 22pt;
    margin:20px 40px 0 40px;
    white-space: pre-wrap;  
    text-align: left;  
    color:#409eff;
    font-weight: 600;
  }
  .writing_part_7_group_blanks{
    display: flex;
    justify-items: left;
    margin: 20px 20px 40px 20px;
  }
}

.listening_part_select_img_group{
  display: flex;
  flex-direction: column;
  margin-bottom:20px;
}
.listening_part_select_img_stem{
  display: flex;
  margin-left: 40px;
  font-size: 12pt;
  font-weight: 550;
  margin-bottom:10px;
}
.listening_part_select_img_stem_playbtn{
  margin-left:10px;
  color:#409eff;
  cursor: pointer;
  font-weight: 600;
}
.listening_part_select_img_stem_playbtn:last-child{
  margin-left:5px;
  margin-right:10px;
}
.listening_part_select_img_options{
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Distribute space evenly */
  margin-left: 40px;
  margin-right: 40px;
}
.listening_part_select_img_quiz{
  display: flex;
  flex-direction:column;
  cursor: pointer;
}
.listening_part_select_img_quiz_pic{
  width: 150px;
  height:100px;
  margin-right: 10px;
  border: 1px solid #000;
}
.listening_part_select_img_quiz_pic_selected{
  border: 6px solid #409eff;
  width: 150px;
  height:100px;
  margin-right: 10px;
}
.listening_part_select_img_quiz_option{
  display: block;
}
.listening_part_select_img_quiz_option_selected{
  font-weight: 600;
  color:#409eff;
}

@media screen and (max-width: 1024px) {
  .listening_part_select_img_group{
    display: flex;
    flex-direction: column;
    margin-bottom:20px;
  }
  .listening_part_select_img_stem{
    display: flex;
    margin-left: 10px;
    font-size: 12pt;
    font-weight: 550;
    text-align: left;
    margin-bottom:10px;
  }
  .listening_part_select_img_stem_playbtn{
    margin-left:10px;
    color:#409eff;
    cursor: pointer;
    font-weight: 600;
  }
  .listening_part_select_img_stem_playbtn:last-child{
    margin-left:5px;
    margin-right:10px;
  }
  .listening_part_select_img_options{
    display: flex;
    flex-direction: column;
    justify-content: space-around; /* Distribute space evenly */
    margin-left: 40px;
    margin-right: 40px;
  }
  .listening_part_select_img_quiz{
    display: flex;
    flex-direction:column;
    cursor: pointer;
  }
  .listening_part_select_img_quiz_pic{
    width: 150px;
    height:100px;
    margin-right: 10px;
    border: 1px solid #000;
  }
  .listening_part_select_img_quiz_pic_selected{
    border: 6px solid #409eff;
    width: 150px;
    height:100px;
    margin-right: 10px;
  }
  .listening_part_select_img_quiz_option{
    display: block;
    text-align: left;
    margin-bottom:20px;
  }
  .listening_part_select_img_quiz_option_selected{
    font-weight: 600;
    color:#409eff;
  }
}

.listening_part_2_group_text_group{
  display: flex;
  flex-direction: column;
  margin: 20px 40px;
}

.listening_part_2_group_text_group_quizs{
  display: flex;
  flex-direction: row; 
  margin-bottom: 30px;
}
.listening_part_2_group_text_group_quizs_stem{
  margin-right: 30px;
}
.listening_part_2_group_text_group_quizs_blank{
 display: flex;
 justify-items:flex-end;
}

@media screen and (max-width: 1024px) {
  
.listening_part_2_group_text_group{
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
}

.listening_part_2_group_text_group_quizs{
  display: flex;
  flex-direction: column; 
  margin-bottom: 30px;
}
.listening_part_2_group_text_group_quizs_stem{
  margin-right: 30px;
  text-align: left;
}
.listening_part_2_group_text_group_quizs_blank{
 display: flex;
 flex-direction: column;
 justify-items:flex-end;
}

}

.listening_part_3_group_stem_options{
  margin:20px 40px;
}
.listening_part_3_group_stem{
  font-weight:600;
  margin-bottom:20px;
  text-align: left;
}
.listening_part_3_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:0 20px 10px 10px; 
  cursor: pointer; 
}
.listening_part_3_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:0 20px 10px 10px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff;
}


.listening_part_4_group_stem{
  font-weight:600;
  margin:20px;
  text-align: left;
  white-space: pre-wrap;  
}

.listening_part_4_group_options{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:20px 40px;
  cursor: pointer;  
}
.listening_part_4_group_options_selected{
  display: block;
  text-align: left;
  font-size: 12pt;
  margin:0 20px 10px 10px; 
  cursor: pointer;
  font-size: 12pt;
  font-weight: 650;
  background:#409eff;
  color:#fff; 
}

.listening_part_5_group_stem{
  font-weight:600;
  margin:20px;
  text-align: left;
  white-space: pre-wrap;  
}

.listening_part5_group{
  display: flex; 
  flex-direction: row;   
  margin:40px 40px;
}
.listening_part5_group_blanks{
  display: flex;
  flex-direction: column;
  margin:0 100px;
  padding-right:100px;
  border-right: 1px solid #00000025;
}
.listening_part5_group_blanks_text{
  display: block;
  margin:10px 0;
  text-align: right;
}
.listening_part5_group_options{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom:20px;
}
.listening_part5_group_options_item{
  display: block;
  margin:10px 0;
}
@media screen and (max-width: 1024px) {
  .listening_part_5_group_stem{
    font-weight:600;
    margin:20px;
    text-align: left;
    white-space: pre-wrap;  
  }
  
  .listening_part5_group{
    display: flex; 
    flex-direction: column;   
    margin:40px 10px;
  }
  .listening_part5_group_blanks{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin:0 10px;
    padding-right:10px;
    border-right: 0px solid #00000025;
  }
  .listening_part5_group_blanks_text{
    display: block;
    margin:10px 0;
    text-align: right;
  }
  .listening_part5_group_options{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom:20px;
    margin-left:10px;
  }
  .listening_part5_group_options_item{
    display: block;
    margin:10px 0;
  }
}
.spoken_answer_group{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin:40px 20px;
  position: relative;
}
.spoken_button{
  position: absolute;
  bottom: 0px;
}
.spoken_group_frame{
  display: flex;
  flex-direction: column;
  margin:0  50px 0 100px;
  padding-right:100px;
  border-right: 1px solid #00000025; 
}
.spoken_answer_group_talk{
  margin-bottom:20px;
}
@media screen and (max-width: 1024px) {
  .spoken_answer_group{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin:40px 20px 80px 20px;
    position: relative;
  }
  .spoken_button{
    position: absolute;
    bottom: -40px;
  }
  .spoken_group_frame{
    display: flex;
    flex-direction: column;
    margin:0  50px 0 100px;
    padding-right:100px;
    border-right: 0px; 
  }
  .spoken_answer_group_talk{
    margin-bottom:20px;
  }
}
</style>