<template>
    <div class="container">
      <div class="setup">
        <span class="title"># 体质评价</span>
        <span class="memo">- 按测评周期展示学生身体数据</span>
        <span class="memo">- 按考核周期展示学生提测成绩</span>
        <span class="memo">- 自动生成学生体质预测图</span>
        <span class="memo">- 智能生成提升体质建议</span>
      </div>
     
  
      <div class="pannel">
        <div class="student-container-studentsList">
          <span class="grade">4年级8班  班主任：周婷</span>
          <el-menu style="height:100%" mode="vertical" @select="selectStudent">
            <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index">
              <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
              <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
              <span>{{s.name}}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="fitness-container">
          <div class="fitness-profiles-pannel">
            <span class="item-title">身体素质</span>
            <el-select v-model="profileDate" placeholder="请选择日期" :width="240"
              @change="handleProfileDateChange"
            >
              <el-option 
              v-for="item in profileDateList" 
              :key="item.value" :label="item.label" 
              :value="item.value"></el-option>
            </el-select>
            <div class="fitness-profiles-pannel-item">
              <div class="fitness-profiles-pannel-item-borad">
                <div class="fitness-profiles-pannel-item-borad-title">
                  <img class="fitness-profiles-pannel-item-borad-title-icon" src="../assets/height.png"/>
                  <span class="fitness-profiles-pannel-item-borad-title-words">身高</span>
                </div>
                <span class="fitness-profiles-pannel-item-unit">cm</span>
                <span class="fitness-profiles-pannel-item-number">{{ height!=null?height:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
              </div>

              <div class="fitness-profiles-pannel-item-borad">
                <div class="fitness-profiles-pannel-item-borad-title">
                  <img class="fitness-profiles-pannel-item-borad-title-icon" src="../assets/weight.png"/>
                  <span class="fitness-profiles-pannel-item-borad-title-words">体重</span>
                </div>
                <span class="fitness-profiles-pannel-item-unit">kg</span>
                <span class="fitness-profiles-pannel-item-number">{{ weight!=null?weight:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
              </div>

              <div class="fitness-profiles-pannel-item-borad">
                <div class="fitness-profiles-pannel-item-borad-title">
                  <img class="fitness-profiles-pannel-item-borad-title-icon" src="../assets/BMI.png"/>
                  <span class="fitness-profiles-pannel-item-borad-title-words">BMI</span>
                </div>
                <span class="fitness-profiles-pannel-item-unit">%</span>
                <span class="fitness-profiles-pannel-item-number">{{ bmi!=null?bmi:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
              </div>

              <div class="fitness-profiles-pannel-item-borad">
                <div class="fitness-profiles-pannel-item-borad-title">
                  <img class="fitness-profiles-pannel-item-borad-title-icon" src="../assets/lung.png"/>
                  <span class="fitness-profiles-pannel-item-borad-title-words">肺活量</span>
                </div>
                <span class="fitness-profiles-pannel-item-unit">ml</span>
                <span class="fitness-profiles-pannel-item-number">{{ lung!=null?lung:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
              </div>

            </div>
          </div>

          <div class="TPP-pannel">
            <div class="pe-testing-pannel">
              <span class="item-title">考试成绩</span> 
              <el-select v-model="testDate" placeholder="请选择日期" :width="240"
              @change="handleTestDateChange"
              >
                <el-option
                v-for="item in testDateList" 
                :key="item.value" :label="item.label" 
                :value="item.value"></el-option>
              </el-select>
              
              <div class="fitness-test-pannel-item">
                <div id="fitness-test-run" class="fitness-test-pannel-item-borad" >
                  <div class="fitness-profiles-pannel-item-borad-title">
                    <span class="fitness-test-pannel-item-borad-title-words">50m 跑</span>
                  </div>
                  <span class="fitness-test-pannel-item-unit">s</span>
                  <span class="fitness-test-pannel-item-number">{{ run!=null?run:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
                </div>

                <div id="fitness-test-curl" class="fitness-test-pannel-item-borad" >
                  <div class="fitness-profiles-pannel-item-borad-title">
                    <span class="fitness-test-pannel-item-borad-title-words">仰卧起坐</span>
                  </div>
                  <span class="fitness-test-pannel-item-unit">个/分</span>
                  <span class="fitness-test-pannel-item-number">{{ curl!=null?curl:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
                </div>

                <div id="fitness-test-forward" class="fitness-test-pannel-item-borad" >
                  <div class="fitness-profiles-pannel-item-borad-title">
                    <span class="fitness-test-pannel-item-borad-title-words">体前屈</span>
                  </div>
                  <span class="fitness-test-pannel-item-unit">cm</span>
                  <span class="fitness-test-pannel-item-number">{{ forward!=null?forward:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
                </div>

                <div id="fitness-test-jump" class="fitness-test-pannel-item-borad" >
                  <div class="fitness-profiles-pannel-item-borad-title">
                    <span class="fitness-test-pannel-item-borad-title-words">跳绳</span>
                  </div>
                  <span class="fitness-test-pannel-item-unit">个/分</span>
                  <span class="fitness-test-pannel-item-number">{{ jump!=null?jump:'&nbsp;&nbsp;- -&nbsp;&nbsp;' }}</span>
                </div>
              </div>

            </div>

            <div class="fitness-predict-pannel">
              <span class="item-title">
                体质预测
              </span>
              <el-button type="primary"  @click="predict(stuID)" plain>生成预测数据</el-button>
              
              <div class="fitness-predict-pannel-content">
                <span class="fitness-predict-pannel-conntent-words" v-if="fitnessPredictionResString!=''">
                  {{ studentsList[stuID].name}} 同学 下次体检时身高可能达到：{{fitnessPredictionResString.split(',')[0].split('=')[1]}}，
                  体重可能达到：{{fitnessPredictionResString.split(',')[1].split('=')[1]}}，
                  BMI可能达到：{{fitnessPredictionResString.split(',')[2].split('=')[1]}}，
                  肺活量可能达到：{{fitnessPredictionResString.split(',')[3].split('=')[1]}}。
                </span>
                <el-empty v-if="fitnessPredictionChartData==null" :image-size="100" description="暂无预测数据" />
                <div v-else style="width:100%; height:500px" id="fitnessCharts"></div>
                <span v-if="fitnessPredictionText!=null">预测信息</span>
                <span  v-if="fitnessPredictionText!=null">{{ fitnessPredictionText }}</span>
              </div>
            </div>

            <div class="health-suggestion">
              <span class="item-title">健康建议</span>
              <span class="health-suggestion-content">{{ healthSuggestion }}</span>
            </div>
          </div>
        </div>
      </div>
     
    </div>   
</template>
   
  <script>
   import axios from 'axios'
   import * as echarts from 'echarts'
   import {ElMessage} from 'element-plus'
    export default {
        name: 'FitnessEva',
        components:{
        },
        data(){
            return{
                      studentsList:[
                              {name:"张小帅",sex:"1",id:1},
                              {name:"马小红",sex:"0",id:2}
                            ],
                      profileDate:null,
                      testDate:null,
                      profileDateList:[{value:0,label:'第7次体检'}],
                      testDateList:[{value:0,label:'第1次体侧'}],
                      height:null,
                      weight:null,
                      lung:null,
                      bmi:null,
                      stuID:null,
                      stuFitnessProfile:[
                        [{"height":145.5,"weight":35.0,"lung":2300}],
                        [{"height":142.0,"weight":30.1,"lung":1997}]
                      ],
                      run:null,
                      jump:null,
                      curl:null,
                      forward:null,
                      stuFitnessTestScore:[
                        [{"run":9.5,"jump":137,"curl":49,"forward":16.7}],
                        [{"run":8.5,"jump":205,"curl":47,"forward":19.5}]
                      ],
                      fitnessPredictionChartData:null,
                      fitnessPredictionText:null,
                      fitnessPredictionResString:"",
                      healthSuggestion:null,
                  }
        },
       
        methods: {
          selectStudent(index){
            this.stuID=index
            this.profileDate=null
            this.testDate=null
            this.height=null
            this.weight=null
            this.bmi=null
            this.lung=null
            this.run=null
            this.curl=null
            this.forward=null
            this.jump=null
            this.fitnessPredictionChartData=null
            this.fitnessPredictionText=""
            this.fitnessPredictionResString=""
            this.healthSuggestion=null
          },     
          handleProfileDateChange(index){
            console.log("stuID,index:",this.stuID,index)
            console.log("stuFitnessProfile:",this.stuFitnessProfile[this.stuID][index])
            this.height=this.stuFitnessProfile[this.stuID][index].height
            this.weight=this.stuFitnessProfile[this.stuID][index].weight
            this.lung=this.stuFitnessProfile[this.stuID][index].lung
            this.bmi=(this.weight/(this.height*this.height/10000)).toFixed(1)
            this.stuFitnessProfile[this.stuID][index].bmi=this.bmi
          },
          handleTestDateChange(index){
            console.log("stuID,index:",this.stuID,index)
            console.log("stuFitnessProfile:",this.stuFitnessTestScore[this.stuID][index])
            this.run=this.stuFitnessTestScore[this.stuID][index].run
            this.jump=this.stuFitnessTestScore[this.stuID][index].jump
            this.curl=this.stuFitnessTestScore[this.stuID][index].curl
            this.forward=this.stuFitnessTestScore[this.stuID][index].forward
          },
          async predict(sid){
            try{
              console.log('sid:',sid)
              const userData={
                          "profiles":{
                            "height":this.stuFitnessProfile[sid][0].height,
                            "weight":this.stuFitnessProfile[sid][0].weight,
                            "bmi":this.stuFitnessProfile[sid][0].bmi,
                            "lung":this.stuFitnessProfile[sid][0].lung,
                          },
                          "test":{
                            "jump":this.stuFitnessTestScore[sid][0].jump,
                            "run":this.stuFitnessTestScore[sid][0].run,
                            "curl":this.stuFitnessTestScore[sid][0].curl,
                            "forward":this.stuFitnessTestScore[sid][0].forward
                          }
              }
            
              const stuName=this.studentsList[sid].name
              const chartURL="http://localhost:8000/ali-stu-get-fitnesschart/"
            // 获取预测图标数据
            await axios.post(chartURL,{
              method:'POST',
              body:userData,
            }).then(res=>{
              //console.log(this.$extract_response_string(res.data))
              // 处理预测返回结果
              this.$extract_response_string(res.data).forEach(item=>{
                  this.fitnessPredictionResString+=item
              })

              // 解析返回结果
              const fitnessResJSON=JSON.parse(this.format2JsonStyle(this.fitnessPredictionResString ))
              console.log("fRJ:",fitnessResJSON.height)

              // 准备图表数据
              this.fitnessPredictionChartData={
                title:{
                  text: stuName + '身体状态预测',
                },
                legend:{data:['身高','体重','BMI','肺活量']},
                xAxis:{
                  type:'category',
                  data:['当前值','预测值']
                },
                yAxis:{
                  type:'value'
                },
                tooltip:{
                  trigger:'axis'
                },
                series:[
                  {
                    name:'身高',
                    stack:'Total',
                    data:[userData.profiles.height,parseFloat(fitnessResJSON.height.replace(/[^0-9]/g,''))],
                    type:'line',
                    smooth:true
                  },
                  {
                    name:'体重',
                    stack:'Total',
                    data:[userData.profiles.weight,parseFloat(fitnessResJSON.weight.replace(/[^0-9]/g,''))],
                    type:'line',
                    smooth:true 
                  },
                  {
                    name:'BMI',
                    stack:'Total',
                    data:[userData.profiles.bmi,parseFloat(fitnessResJSON.bmi.replace(/[^0-9]/g,''))],
                    type:'line',
                    smooth:true 
                  },
                  {
                    name:'肺活量',
                    stack:'Total',
                    data:[userData.profiles.lung,parseFloat(fitnessResJSON.lung.replace(/[^0-9]/g,''))],
                    type:'line',
                    smooth:true 
                  } 
              ]
              }
            })

            //生成图表
            const fitnessCharts=echarts.init(document.getElementById('fitnessCharts'));
            fitnessCharts.setOption(this.fitnessPredictionChartData)
            
            // 获取健康建议
            const req_url="http://localhost:8000/ali-health-advice/"
           
              const res=await fetch(req_url,{
              method:'POST',
              body:JSON.stringify(userData),
              headers:{
                "Content-Type":"application/json",
              }
            })
            if(!res.ok){
              
              console.log('请求发送失败',res)
              ElMessage({
                type:'error',
                message:'请求发送失败'
              })
            }
            const reader=res.body.getReader()
            let {value,done}=await reader.read()
            //console.log("value=",value)
            while (!done) {  
              ({ value, done } = await reader.read());  
              this.healthSuggestion+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
              if (done) {  
                break; // 跳出循环，因为流已经结束  
              }  
            }  
            }catch(e){
              ElMessage({
                type:'error',
                message:"首先请确定已选择了学生。如果还有问题请查看是否有如下现象。",e
              })
            }
            
          },

          format2JsonStyle(input) {  
            console.log("input:",input)
            let result = {};
            // 将字符串按照逗号分割成数组
            let pairs = input.split(",");

            // 遍历数组,将每个键值对转换为对象的属性
            for (let pair of pairs) {
              let [key, value] = pair.trim().split("=");
              value = value.replace(/'/g, '');  
              result[key] = value;
            }  
            console.log("result-->",result);
            return JSON.stringify(result);
        }     
    },
  }
  </script>
   
<style scoped lang="scss">
.container{
  display: flex;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
  flex-direction: column;
}

.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }
  .title{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  font-size: 14pt;
  color:#7d8084;
  }
  .memo{
  display: flex;
  margin:10px 20px 3px 40px;
  font-size: 10pt;
  font-weight: 400;
  color:#979a9f;
  }
  .memo:last-child{
  margin-bottom: 30px;
  }
  
  .pannel{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content:flex-start;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
  }

.grade{
  display: flex;
  justify-content: left;
  margin: 10px 5px;
  font-weight: bold;
  }
.student-container-studentsList{
  margin-top:20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x:hidden;
  }

.fitness-container{
  display: flex;
  flex-direction: row;
  flex:1;
}
.fitness-profiles-pannel{
 display: flex;
 flex-direction: column; 
 flex: 1;
 margin: 70px 20px;
}
.fitness-profiles-pannel-item{
  display: flex;
  flex-direction: column;
}
.fitness-profiles-pannel-item-borad{
  display: flex;  
  flex-direction: column;
  background: rgba(172, 175, 189, 0.15);
  border: 1px solid rgba(68, 74, 98, 0.15);
  border-radius: 5px;
  height: 130px;
  align-items: flex-start;
  margin:5px 0;
}
.fitness-profiles-pannel-item-borad-title{
  display: flex;
  flex-direction: row;
}
.fitness-profiles-pannel-item-borad-title-icon{
  margin: 10px;
  width: 25px;
  height: 25px;
}
.fitness-profiles-pannel-item-borad-title-words{
  display:inline-flex;
  margin-top: 12px; 
  font-weight: 600;
}
.fitness-profiles-pannel-item-unit{
  display: inline-flex;
  margin-left: 50%;
}
.fitness-profiles-pannel-item-number{
  display: inline-flex;
  font-family:'Times New Roman', Times, serif;
  font-size: 25pt;
  font-weight: 700;
  margin-left:40%;
}
.item-title{
  font-size:16px;
  font-weight: 600; 
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.TPP-pannel{
  display: flex;
  flex-direction: column;  
  flex: 2;
  margin: 70px 20px;
}
.pe-testing-pannel{
  display: flex;
  flex-direction: column; 
}
.fitness-test-pannel-item{
  display: flex;
  flex-direction: row;
}
.fitness-test-pannel-item-borad{
  display: flex;  
  flex-direction: column;
  background: rgba(172, 175, 189, 0.15);
  
  border: 1px solid rgba(68, 74, 98, 0.15);
  border-radius: 5px;
  height: 100%;
  width:25%;
  align-items: flex-start;
  margin:5px 5px 0 0; 
  position: relative;
}
#fitness-test-run::before{
  background-image:url('https://sam-test.oss-cn-beijing.aliyuncs.com/assets/run.png');
  filter: opacity(0.1);
  position: absolute;
  background-size:cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;  
  right: 0; 
  content:"";
}
#fitness-test-forward::before{
  background-image:url('https://sam-test.oss-cn-beijing.aliyuncs.com/assets/bodyfold.png');
  filter: opacity(0.1);
  position: absolute;
  background-size:cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;  
  right: 0; 
  content:"";
}
#fitness-test-curl::before{
  background-image:url('https://sam-test.oss-cn-beijing.aliyuncs.com/assets/downup.png');
  filter: opacity(0.05);
  position: absolute;
  background-size:cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;  
  right: 0; 
  content:"";
}
#fitness-test-jump::before{
  background-image:url('https://sam-test.oss-cn-beijing.aliyuncs.com/assets/jumprope.png');
  filter: opacity(0.1);
  position: absolute;
  background-size:cover;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;  
  right: 0; 
  content:"";
}
.fitness-test-pannel-item-borad-title-words{
  display:inline-flex;
  margin: 11px; 
  font-weight: 450;
}
.fitness-test-pannel-item-unit{
  display: inline-flex;
  margin-left: 50%; 
}
.fitness-test-pannel-item-number{
  display: inline-flex;
  font-family:'Times New Roman', Times, serif;
  font-size: 25pt;
  font-weight: 700;
  margin-left:30%; 
}
.fitness-predict-pannel{
  display: flex;
  flex-direction: column; 
  margin-top: 50px;
}
.fitness-predict-pannel-content{
  display: flex;
  flex-direction: column;
}
.fitness-predict-pannel-conntent-words{
  display: flex;
  word-wrap: wrap;
  word-break: break-all;
  font-size: 16px;
  color:#7d8084;
  margin: 20px 0;
} 
.health-suggestion{
  display: flex;
  flex-direction:column; 
  margin-top: 20px;
}
.health-suggestion-content{
  display: flex;
  word-wrap: wrap;
  word-break: break-all;
  font-size: 16px;
  color:#7d8084;
  margin: 20px 0;
}
</style>