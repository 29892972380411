<template>
    <div>
        <el-text class="mx-1" size="Large">AI学习能力分析预测模型</el-text>
        
            <div class="UserPanel">
                <div  class="UserPanel_Profiles">
                    <img  class="UserPanel_Profiles_avatar" :src="avatar"/>
                    <div   class="UserPanel_Profiles_name"> 
                        <el-text class="mx-1">张天成</el-text>
                    </div>
                </div>

                <div class="AnalysisPanel">  
                    <div class="AnalysisPanel-Control">
                        <div  class="AnalysisPanel-Button">
                            <el-button  type="primary" @click="analysising">预测分析</el-button>      
                        </div>
                        
                        <div  class="AnalysisPanel-Text">
                            <el-text>{{this.admissionBatchPrediction}}</el-text>
                            <el-text>{{this.professionalCodePrediction}}</el-text>
                        </div>
                    </div>
                    
                </div>      
                
            <el-scrollbar class="scrollbar-wrapper">
                <div  class="UserPanel_Profiles_enterSocre">入学成绩
                    <div   class="UserPanel_Profiles_enterSocre_Row">
                        <div  class="UserPanel_Profiles_enterSocre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">数学成绩</span> 
                            <div class="UserPanel_Profiles_enterSocre_Column_Input">
                                <el-input @input="validateIntegerRange(enter.math)" v-model="enter.math" style="width: 240px" placeholder="输入数学成绩" /></div>
                        </div>

                        <div  class="UserPanel_Profiles_enterSocre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">英语成绩</span> 
                            <div class="UserPanel_Profiles_enterSocre_Column_Input">
                                <el-input @input="validateIntegerRange(enter.english)"  v-model="enter.english" style="width: 240px" placeholder="输入英语成绩" /></div>
                        </div>

                        <div  class="UserPanel_Profiles_enterSocre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">科学成绩</span> 
                            <div class="UserPanel_Profiles_enterSocre_Column_Input">
                                <el-input @input="validateIntegerRange(enter.science)"  v-model="enter.science" style="width: 240px" placeholder="输入科学成绩" /></div>
                        </div>

                    </div>
                </div>

                <div  class="UserPanel_Profiles_Annual_Socre">年度成绩
                    <span>第一年</span>
                    <div   class="UserPanel_Profiles_Annual_Socre_Row">
                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">数学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[0].math)"  v-model="annual[0].math" style="width: 240px" placeholder="输入数学成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">英语成绩</span> 
                            <el-input @input="validateIntegerRange(annual[0].english)" v-model="annual[0].english" style="width: 240px" placeholder="输入英语成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">科学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[0].science)" v-model="annual[0].science" style="width: 240px" placeholder="输入科学成绩" />
                        </div>

                    </div>
                </div>

                <div  class="UserPanel_Profiles_Annual_Socre">年度成绩
                    <span>第二年</span>
                    <div   class="UserPanel_Profiles_Annual_Socre_Row">
                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">数学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[1].math)" v-model="annual[1].math" style="width: 240px" placeholder="输入数学成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">英语成绩</span> 
                            <el-input @input="validateIntegerRange(annual[1].english)" v-model="annual[1].english" style="width: 240px" placeholder="输入英语成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">科学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[1].science)" v-model="annual[1].science" style="width: 240px" placeholder="输入科学成绩" />
                        </div>

                    </div>
                </div>

                <div  class="UserPanel_Profiles_Annual_Socre">年度成绩
                    <span>第三年</span>
                    <div   class="UserPanel_Profiles_Annual_Socre_Row">
                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">数学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[2].math)" v-model="annual[2].math" style="width: 240px" placeholder="输入数学成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">英语成绩</span> 
                            <el-input @input="validateIntegerRange(annual[2].english)" v-model="annual[2].english" style="width: 240px" placeholder="输入英语成绩" />
                        </div>

                        <div  class="UserPanel_Profiles_Annual_Socre_Column">
                            <span class="UserPanel_Profiles_enterSocre_Column_Title">科学成绩</span> 
                            <el-input @input="validateIntegerRange(annual[2].science)" v-model="annual[2].science" style="width: 240px" placeholder="输入科学成绩" />
                        </div>

                    </div>
                </div>
            </el-scrollbar>
        </div>
       
    </div>
</template>
   
  <script>
    import {ElLoading,ElMessage} from 'element-plus'
    import axios from 'axios'
    export default {
        name: 'PredictPage',
        // setup(){
        //     const annual=ref([{math:'',english:'',science:''},
        //     {math:'',english:'',science:''},
        //     {math:'',english:'',science:''}])
            
        //     const enter=ref({math:'',english:'',science:''})
               
        //     return {annual,enter}
        // },
        data(){
            return{
                    avatar:"https://nimg.ws.126.net/?url=http%3A%2F%2Fdingyue.ws.126.net%2F2022%2F1221%2Fa9cf09ecj00rn8o2o003hc0010200vkm.jpg&thumbnail=660x2147483647&quality=80&type=jpg",
                    enter:{math:0,english:0,science:0},
                    annual:[{math:0,english:0,science:0},
                            {math:0,english:0,science:0},
                            {math:0,english:0,science:0}],
                    hasErr:false,
                    admissionBatchPrediction:null,
                    professionalCodePrediction:null,
            }
    },
    computed:{
        computedEnter:{
            get(){
                return  this.enter
            },
            set(newValue){
                this.enter=newValue
            }
        },
        computedAnnual:{
            get(){
                return this.annual 
            },
            set(newValue){
                this.annual=newValue
            }
        }
    },
    methods: {
        async analysising(){
            let median
            console.log("analysising的hasErr值：",this.hasErr)
            
            if(this.hasErr==false){
                median=this.calcMedian(this.annual)
                console.log("median: ",median)
                const data={enter:this.enter,annual:median}
                const loading=ElLoading.service({
                lock:true,
                text:"AI分析中……",
                background:'rgba(0,0,0,0.7)'
            })
            console.log("AI分析结果",await this.requestAI(data))
                if (this.hasErr==false){
                    loading.close()
                }
            }else{
                ElMessage({
                    type:'error',
                    message:'请检查数据，应为0到150之间的整数'
                })
            }
           
        },
        validateIntegerRange(vir){
            const intValue = parseInt(vir, 10);  
            if (isNaN(intValue) || intValue < 0 || intValue > 150) {  
                ElMessage({
                    type:'error',
                    message:'请输入一个0到150之间的整数'
                })
                this.hasErr=true
            }else{
                this.hasErr=false
            } 
        },
       
        async requestAI(data){
                console.log("requestAI:",data)
                const predictData={
                    sex_reward:1,
                    math_reward:data.annual.math,
                    english_reward:data.annual.english,
                    science_reward:data.annual.science,
                    math_enterace:data.enter.math,
                    english_enterace:data.enter.english,
                    science_enterace:data.enter.science
                }
                const headers={
                    'Content-type': 'application/json'
                }
                await axios.post('http://127.0.0.1:8080/predict/',predictData,{headers})
                .then((res)=>{
                    console.log("requestAI-res",res)
                    if (res.data.code==20000){
                        console.log("ADSymbol",res.data.data.ad[0])
                        if(res.data.data.ad[0]==0){
                            this.admissionBatchPrediction="分析结果：依照现有数据判断，该学生【不能被录取】"
                        }else if(res.data.data.ad[0]==1){
                            this.admissionBatchPrediction="分析结果：依照现有数据判断，该学生很可能被【本科 1 批次录取】"
                        }else if(res.data.data.ad[0]==2){
                            this.admissionBatchPrediction="分析结果：依照现有数据判断，该学生很可能被【本科 2 批次录取】" 
                        }else if(res.data.data.ad[0]==3){
                            this.admissionBatchPrediction="分析结果：依照现有数据判断，该学生很可能被【专科 批次录取】" 
                        }

                        console.log("PCSymbol",res.data.data.pc[0])
                        if(res.data.data.pc[0]!=0){
                            this.professionalCodePrediction="您有可能被专业【 "+ res.data.data.pc[0] +" 】录取" 
                        }else{
                            this.professionalCodePrediction=null
                        }
                    }else{
                        ElMessage({
                        type:'error',
                        message:'分析失败'
                    })
                }             
                }).catch((error)=>{
                    ElMessage({
                        type:'error',
                        message:'分析失败'+error
                    })
                })
                
                    
        },
        calcMedian(data){
            var columns = Object.keys(data[0]); // 获取列名
            var medians = {};

            columns.forEach(function(column) {
                var columnValues = data.map(function(obj) {
                return parseInt(obj[column]);
                });

                columnValues.sort(function(a, b) {
                return a - b;
                });

                var median;
                var middleIndex = Math.floor(columnValues.length / 2);

                if (columnValues.length % 2 === 0) {
                median = (columnValues[middleIndex - 1] + columnValues[middleIndex]) / 2;
                } else {
                median = columnValues[middleIndex];
                }

                medians[column] = median;
            });

            return medians;     
        }
    },
  }
  </script>
   
<style scoped>
    .scrollbar-wrapper {
         height: 700px;
    }
    .UserPanel{
        margin:20px 200px ; 
    } 
    .UserPanel_Profiles{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .UserPanel_Profiles_avatar{
        border: 1px solid #ccc;
        border-radius: 30px;
        width:60px;
        height:60px;
    }
    .UserPanel_Profiles_name{
        margin: 25px;
    }
    .UserPanel_Profiles_enterSocre{
       margin-top:30px;
       padding-top:20px;
        background: rgba(188,188,188,0.25);
    }
    .UserPanel_Profiles_enterSocre_Row{
        display: flex;
        flex-direction: row;
        justify-content: center; 
    }
    .UserPanel_Profiles_enterSocre_Column{
        margin: 20px 30px 20px 0;
        display: flex;
        flex-direction: column;
    }
    .UserPanel_Profiles_enterSocre_Column:last-child{
        margin-right:0 ;
    }
    .UserPanel_Profiles_enterSocre_Column_Title{
       flex: 1;
    }
    .UserPanel_Profiles_enterSocre_Column_Input{
       flex: 5;
    }
    .UserPanel_Profiles_Annual_Socre{
        margin-top:30px ;
        padding-top:20px;
        background: rgba(188,188,188,0.25)
    }
    .UserPanel_Profiles_Annual_Socre_Row{
        display: flex;
        flex-direction: row;
        justify-content: center;  
    }
    .UserPanel_Profiles_Annual_Socre_Column{
        margin: 20px 30px 20px 0;
        display: flex;
        flex-direction: column;
    }
    .UserPanel_Profiles_Annual_Socre_Column:last-child{
        margin-right: 0px; 
    }
    .AnalysisPanel{
       display: flex;
       flex-direction: column;
    }
    .AnalysisPanel-Control{
        
    }
.AnalysisPanel-Button{
    margin-top:10px ;
}
 .AnalysisPanel-Text{
    margin: 20px 0;
    font-size: 12pt;
    font-weight: 550;
 }  
</style>