<template>
  <div class="container">

    <div class="setup">
      <span class="title"># 作文批改</span>
      <span class="memo">- 自动批改作文</span>
      <span class="memo">- 自动识别错字病句</span>
      <span class="memo">- 自动标注好词好句</span>
      <span class="memo">- 支持收录范文</span>
      <span class="memo">- 提供智能学习建议</span>
    </div>
   

    <div class="pannel">
      <div class="top-navigator">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <span class="grade">4年级8班  班主任：周婷</span>

          <el-tab-pane label="作文批改" name="first">
            <div class="writting-correct">
              <div class="student-container-studentsList">
                <el-menu style="height:100%" mode="vertical" @select="selectStudent">
                  <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index">
                    <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
                    <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
                    <span>{{s.name}}</span>
                  </el-menu-item>
                </el-menu>
              </div>
              

                  <div class="writting-correct-pannel">
                    <div class="writting-correct-tray">    
                      <el-card class="writting-correct-tray-card" @click="correcting(i.status)" v-for="(i,index) in writtings[stuIndex]" :key="index">
                          <div class="writting-correct-tray-card-status-tag">
                            <el-tag type="primary" v-if="i.status==1">已提交</el-tag>
                            <el-tag type="danger" v-else>待提交</el-tag>
                          </div>
                        
                          <div class="writting-correct-tray-card-title">
                            <span class="writting-correct-tray-card-title-name">{{ i.title }}</span>
                            <span v-if="i.status==1" class="writting-correct-tray-card-title-cr">得分：{{ i.cr }}</span>
                          </div>
                        
                          <div class="writting-correct-tray-card-description">
                            <span class="writting-correct-tray-card-description-words">发布时间：{{i.date}}</span>
                          </div>
                      </el-card>
                    </div>
  
                  </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="经典范文" name="second">
            <div class="sample-essay">
              <div  class="sample-essay-student-list">
                <el-menu style="height:100%" mode="vertical" @select="selectUnitSerial">
                  <el-menu-item v-for="(s,index) in writtingList" :key="index" :index="index">
                    <span>{{s}}</span>
                  </el-menu-item>
                </el-menu>    
              </div>
                
              <div class="sample-essay-pannel">
                  <el-card class="writting-correct-sample-essay-tray-card" v-for="(i,index) in sampleEssay[unitSerial]" :key="index" @click="showEssayDialog(i.name)">
                    <div class="writting-correct-sample-essay-tray-card-title">
                      <span class="writting-correct-sample-essay-tray-card-title-title">《{{ i.title }}》</span>
                    </div>

                    <div class="writting-correct-sample-essay-tray-card-title">
                      <span class="writting-correct-sample-essay-tray-card-name"><el-icon color="#7f77f3"><EditPen /></el-icon>&nbsp;{{ i.name }}</span>
                    </div>

                    <div class="writting-correct-sample-essay-tray-card-description">
                      <span class="writting-correct-sample-essay-tray-card-description-words">得分：{{i.score}}</span>
                    </div>
                  </el-card>
              </div>
            </div>
          </el-tab-pane>

          

          <el-tab-pane label="智能学习建议" name="third">
            <div class="wisdom-proposal">
              <div class="student-container-studentsList">
                <el-menu style="height:100%" mode="vertical">
                  <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index"  @click="analysisStudent(s.name,index)">
                    <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
                    <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
                    <span>{{s.name}}</span>
                  </el-menu-item>
                </el-menu>
              </div>

              <div class="student-situation-pannel">
                <div style="width:500px; height:500px" id="echarts"></div>
              </div>

              <div class="student-proposal-pannel">
                <span class="student-proposal-pannel-title">填写学习建议</span>
                <el-input v-model="suggestion" type="textarea" :autosize="{minRows:6,maxRows:10}"></el-input>
                <el-button plain type="primary" @click="generateSuggestion">一键生成</el-button>
              </div>
            </div>
          </el-tab-pane>

        </el-tabs>
      </div>
    </div>

      <el-dialog
        v-model="correctingDialog"
        title="批改作文"
        width="1000"
        :before-close="scanClose"
      >

          <div class="scan-homework-body">

            <div class="scan-zone">
              <div v-if="ocrProgressing!=2" class="scanning-overlay">
              </div>
              <div> 
                <el-tag v-if="ocrProgressing==0"> 请将作文放在此区域内</el-tag>
                <img v-if="ocrProgressing!=0" style="width:300px;height:auto" :src="writtingUrl" />
            </div>
            </div>
            <div class="correcting-zone">
                <span v-if="ocrProgressing==2" class="correcting-zone-title">错字</span>
                <span v-if="ocrProgressing==2" class="correcting-zone-description">乔 ………… 骄</span>
                <span v-if="ocrProgressing==2" class="correcting-zone-description">仉 ………… 佩</span>
                <el-divider/>
                <span v-if="ocrProgressing==2" class="correcting-zone-title">好词好句</span>
                <span v-if="ocrProgressing==2" class="correcting-zone-description">它们就像天上的星星，闪闪发亮，多的数不清；又像海底的珍珠，光彩夺目。</span>
                <span v-if="ocrProgressing==2" class="correcting-zone-description">【评语】你的这句描述展现了出色的想象力和语言表达能力。通过‘天上的星星’和‘海底的珍珠’这两个富有诗意的比喻，你不仅生动地描绘了事物的美丽与繁多，还巧妙地运用了对比与融合的手法，使句子更加生动有趣。同时，你对细节的捕捉和情感的传递也令人印象深刻，让读者能够深切感受到你对美好事物的赞美和向往。。</span>
                <el-divider/>
                <span v-if="ocrProgressing==2 && isCorrect!=2"  class="correcting-zone-title">打分: </span>
                <el-input-number  v-if="ocrProgressing==2 && isCorrect==1" v-model="evalutationWrittingScore" :min="0" :max="100" />
                <span v-if="ocrProgressing==2 && isCorrect==2" class="correcting-zone-total">得分：95</span>

            </div>
          </div>


        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary" @click="scanClose">
              收录成范文
            </el-button>
            <el-button   @click="scanClose">
              完成
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog
        v-model="showEssay"
        title="范文展示"
        width="700"
        :before-close="essayClose"
      >

          <div class="essay-body">
             <span class="correcting-zone-title">本文作者：{{essayWritter}}</span>
             <img  style="width:600px;height:auto" :src="writtingUrl" />
          </div>


        <template #footer>
          <div class="dialog-footer">
            <el-button type="primary"  @click="essayClose">
              完成
            </el-button>
          </div>
        </template>
      </el-dialog>

  </div>   
</template>
 
<script>
import {ElMessage} from 'element-plus'
import * as echarts from 'echarts'
 export default {
      name: 'WrittingCorrect',
      components:{
        
      },
     
      data(){
          return{
                    activeName: "third", // 被激活的tab_Key

                    correctingDialog:false, // 批改窗口 

                    sampleEssay:[     // 示例文章
                      [
                        {name:"张小帅",title:"晨曦中的山林秘境",score:"100"},
                        {name:"李向明",title:"夜幕低垂下的都市灯火",score:"98"}],
                      [
                        {name:"胡子路",title:"豆芽成长记",score:"97"},
                        {name:"王芳",title:"校园一角的四季更迭",score:"94"}
                      ]
                    ], 
                    writtingList: [  // 作文标题mock
                      "第一单元 写景习作",
                      "第二单元 观察日记",
                      "第三单元 写童话",
                      "第四单元 写动物",
                      "第五单元 介绍世界遗产",
                      "第六单元 看图写作文",
                      "第七单元 书信习作",    
                      "第八单元 想象习作"
                    ],
                        stuIndex:null,
                        
                        writtings:[
                          [
                            {date:"2024-07-11",title:"第一单元 写景习作",status:1,cr:"90"},
                            {date:"2024-07-09",title:"第二单元 观察日记",status:1,cr:"95"}
                          ],
                          [
                            {date:"2024-07-11",title:"第一单元 写景习作",status:0,cr:"0"},
                            {date:"2024-07-09",title:"第二单元 观察日记",status:1,cr:"87"} 
                          ]
                        ],
                        studentsList:[
                              {name:"张小帅",sex:"1",id:1},
                              {name:"马小红",sex:"0",id:2}
                            ],
                        writtingUrl:null,
                        ocrProgressing:0,

                        stuSituation:[],
                        unitSerial:null,
                        evalutationWrittingScore:0,
                        isCorrect:null,
                        showEssay:false,
                        essayWritter:null,
                        suggestion:null,
          }
      },
      
      methods: {
        correcting(status){
          if(status!=1){
            this.correctingDialog=true 
            this.ocrProgressing=0 // 显示扫描界面
            this.isCorrect=1  // 判作文
            this.scanWrittings()

          }else if(status==1){
            this.correctingDialog=true 
            this.ocrProgressing=2 // 显示作文内容
            this.isCorrect=2  // 展示作文
            this.writtingUrl="https://sam-test.oss-cn-beijing.aliyuncs.com/correctwrittings.png"
          }
        },
        preview(){
          this.previewDialog=true
        },
        scanClose(){
          this.correctingDialog=false
        },
        handleClose(){
          console.log("关闭对话框")
          this.previewDialog=false
        },
        selectStudent(index){
          console.log(index) 
          this.stuIndex=index
        },
        analysisStudent(name,index){
          console.log("name+index:",name,index) 
          this.stuIndex=index
          const chart =echarts.init(document.getElementById('echarts'));
          const options={
            title:{
              text:name+" 作文能力评价"
            },
            legend:{
              data:[name]
            },
            radar:{
                indicator:[
                  {name:"内容完整性",max:100},
                  {name:"结构清晰度",max:100},
                  {name:"语言表达",max:100},
                  {name:"创新性",max:100},
                  {name:"逻辑性",max:100},
                  {name:"文采与修辞",max:100},
                ],
                center:['45%','50%'],
                radius:120,
                axisName:{
                  color:'#fff',
                  backgroundColor:'#666',
                  borderRadius:3,
                  padding:[3,5]
                }
              },
              series:{
                type:'radar',
                name:name,
                data:[{
                  value:[80,92,100,75,64,80],
                  name:name,
                  areaStyle:{
                    color:  new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                      {
                        color: 'rgba(236, 244, 255, 0.1)',
                        offset: 1
                      },
                      {
                        color: 'rgba(121, 169, 255, 0.9)',
                        offset: 0
                      }
                    ])
                  }
                }]
              }
            
          }
          chart.setOption(options)
        },
        selectUnitSerial(index){
          this.unitSerial=index
        },
        scanWrittings(){ 
          setTimeout(()=>{
            this.ocrProgressing=1
            this.writtingUrl="https://sam-test.oss-cn-beijing.aliyuncs.com/writting.png" // 完成扫描
            setTimeout(()=>{
              this.writtingUrl="https://sam-test.oss-cn-beijing.aliyuncs.com/correctwrittings.png" // 完成扫描
          },2000)
          setTimeout(()=>{
            this.ocrProgressing=2   // 显示结果
          },2000)
          },3000)
          
          console.log("this.ocrProgressing:",this.ocrProgressing)
        },
      
        async getStudentSituation(index){
          this.stuIndex=index
          const req_url="http://localhost:8000/ali-stu-situation/"
              const zhangData={
                          "math":"作业提交2次，《大数认识》作业正确率100%，《公顷和平方千米》作业正确率100%",
                          "chinese":"作业提交2次，《观海》作业正确率80%，阅读理解和判断题各错一道。《走月亮》作业正确率100%",
                          "english":"作业提交1次，《Unit1 My classroom》作业正确率40%，第2，3，4题错误。《My schoolbag》作业待提交",
                          "student_name":"张小帅",
              }
              const maData={
                          "math":"作业提交2次，《大数认识》作业正确率50%，第1，2，3题错误。《公顷和平方千米》作业正确率50%。第4，7，9题错误",
                          "chinese":"作业提交2次，《观海》作业正确率100%，《走月亮》作业正确率100%",
                          "english":"作业提交2次，《Unit1 My classroom》作业正确率70%，第3，8题错误。《My schoolbag》作业正确率100%",
                          "student_name":"马小红",
              }

              const res=await fetch(req_url,{
              method:'POST',
              body:JSON.stringify(index==0?zhangData:maData),
              headers:{
                "Content-Type":"application/json",
              }
            })
            if(!res.ok){
              
              console.log('请求发送失败',res)
              ElMessage({
                type:'error',
                message:'请求发送失败'
              })
            }
            const reader=res.body.getReader()
            let {value,done}=await reader.read()
            //console.log("value=",value)
            while (!done) {  
              ({ value, done } = await reader.read());  
              this.stuSituation[index]+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
              if (done) {  
                break; // 跳出循环，因为流已经结束  
              }  
            }  
        },
        essayClose(){
          this.showEssay=false
        },
        showEssayDialog(name){
          this.showEssay=true
          this.essayWritter=name
          this.writtingUrl='https://sam-test.oss-cn-beijing.aliyuncs.com/correctwrittings.png'
        },
        async generateSuggestion(){
          const req_url="http://localhost:8000/ali-writting-suggestion/"
                
                const Data={
                            "content_integrity":"82",
                            "structural_clarity":"92",
                            "language_expression":"100",
                            "innovation":"75",
                            "logicality":"64",
                            "literary_talent_rhetoric":"80",
                            "student_name":"马小红"
                }

                const res=await fetch(req_url,{
                method:'POST',
                body:JSON.stringify(Data),
                headers:{
                  "Content-Type":"application/json",
                }
              })
              if(!res.ok){
                
                console.log('请求发送失败',res)
                ElMessage({
                  type:'error',
                  message:'请求发送失败'
                })
              }
              const reader=res.body.getReader()
              let {value,done}=await reader.read()
              //console.log("value=",value)
              while (!done) {  
                ({ value, done } = await reader.read());  
                this.suggestion+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
                if (done) {  
                  break; // 跳出循环，因为流已经结束  
                }  
              }  

        }
  }
}
</script>
 
<style scoped lang="scss">
.container{
  display: flex;
  justify-content: center; /*水平居中*/
  align-items: center; /*垂直居中*/
  flex-direction: column;
}
.setup{
display: flex;
margin-bottom: 20px;
flex-direction: column;
justify-content:center;
background: #fff;
width: 75%;
border: 1px solid rgba(68,74,98,0.15);
border-radius: 2px;
box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.title{
display: flex;
margin:30px 20px 10px 20px;
font-size: 15pt;
font-weight: 600;
font-size: 14pt;
color:#7d8084;
}
.memo{
display: flex;
margin:10px 20px 3px 40px;
font-size: 10pt;
font-weight: 400;
color:#979a9f;
}
.memo:last-child{
margin-bottom: 30px;
}

.pannel{
display: flex;
margin-bottom: 20px;
flex-direction: row;
justify-content:flex-start;
background: #fff;
width: 75%;
border: 1px solid rgba(68,74,98,0.15);
border-radius: 2px;
box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.top-navigator{
margin:20px;
}
.grade{
display: flex;
justify-content: left;
margin: 10px 5px;
font-weight: bold;
}

.writting-correct{
  display: flex;
  flex-direction: row;
  }
  .student-container-studentsList{
  margin-top:20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x:hidden; 
  }
  .writting-correct-pannel{
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  background:#fcfcfc;
  }
  .writting-correct-tray{
    display: flex;
    overflow-y:auto;
    flex-wrap:wrap; 
    flex-direction: row;
    border-radius: 10px;
    }
    
    .writting-correct-tray-title{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 10px 20px;
    }
    .writting-correct-tray-card{
    margin: 10px 20px;
    cursor:pointer;

    }
    
    .writting-correct-tray-card-status-tag{
    display: flex;
    justify-content: flex-start;
    margin: 0 0 20px 0;
    }
    
    .writting-correct-tray-card-title{
    display: flex;
    justify-content: flex-start;
    margin: 0 0 20px 0; 
    }
    
    .writting-correct-tray-card-description{
    display: flex;
    justify-content: flex-start;
    }
    
    .writting-correct-tray-card-title-name{
    font-size: 16px;
    font-weight: 450;
    word-wrap: normal;
    display:block;
    margin-right:30px;
    }
    .writting-correct-tray-card-title-cr{
    display:block;
    font-size: 16px;
    font-weight: 400;
    color:#0c67dd;
    }
    .writting-correct-tray-card-description-words{
    font-size: 13px;
    font-weight: 300;
    color:#7d8084;
    }
  
  .sample-essay{
      display: flex;  
      flex-direction: row;
      }
  
  .sample-essay-student-list{
    display: flex;
  }
  
  .sample-essay-pannel{
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    background:#fcfcfc;
  }

  .writting-correct-sample-essay-tray-title{
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 10px 20px;
  }
  .writting-correct-sample-essay-tray-card{
    margin: 10px 20px;
    cursor:pointer;
    border-radius: 10px;
    max-height:150px;
  }
  
  .writting-correct-sample-essay-tray-card-title{
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 0; 
  }
  
  .writting-correct-sample-essay-tray-card-description{
  display: flex;
  justify-content: flex-start;
  }
  
  .writting-correct-sample-essay-tray-card-title-title{
    font-size: 16px;
    font-weight: 500;
    word-wrap: normal;
    display:block;
    margin-right:30px;
    }
  
  .writting-correct-sample-essay-tray-card-title-name{
  font-size: 14px;
  font-weight: 400;
  word-wrap: normal;
  display:block;
  margin-right:30px;
  }
  
  .writting-correct-sample-essay-tray-card-description-words{
  font-size: 13px;
  font-weight: 300;
  color:#7d8084;
  }
.preview-title{
font-weight: 500;
font-size: 16px;
text-align: left;
display:flex;
justify-content: flex-start;
margin: 20px;
}
.preview-info{
display: flex;
justify-content: flex-start;
text-align: left;
margin:20px;
}
.preview-info:last-child{
margin: 20px;
}

.scan-homework-body{
display: flex;
flex-direction: row;
}
.scan-zone{
position: relative;
height: 600px; /* 根据需要调整高度 */
width:400px;
overflow: hidden;
border: 1px solid rgba(31, 74, 159, 0.85);
border-radius: 10px;
padding-top:60px; 
text-align: center;
}
.correcting-zone{
display:flex; 
flex-direction: column;
margin-left:20px;
flex:1;
}

@keyframes scan-effect {
0% {
  transform: translateY(-100%);
}
100% {
  transform: translateY(100%);
}
}

.scanning-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, rgba(0, 191, 255, 0.357), transparent); /* 扫描效果的颜色渐变 */
  animation: scan-effect 2s linear infinite
}

.correcting-zone-title{
display: inline-block;
font-size: 16px;
font-weight: 450;
margin: 20px 0;
}
.correcting-zone-title{
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0;
  text-align: left;  
}
.correcting-zone-description{
display: inline-block;
font-size: 14px;
font-weight: 450;
margin: 20px 0; 
text-align: left;
}
.correcting-zone-total{
display: inline-block;
font-size: 16px;
font-weight: 550;
color:#0c67dd;
margin: 20px 0;
text-align: left;
}

.wisdom-proposal{
display: flex;
flex-direction: row; 
}

.student-situation-pannel{
width:450px;
background: #f8f8fb;
margin-left:10px;
display: flex;
flex-direction: column;
}
.student-situation-pannel-title{
display: flex;
justify-content: flex-start;
margin: 20px;
font-size: 15px;
font-weight: 500;
border-bottom: 1px solid #7d8084;
}
.student-situation-pannel-description{
display: flex;
justify-content: flex-start;
font-size: 16px;
font-weight: 450;
margin: 0 20px;
line-height: 28px;
}
.student-proposal-pannel{
width:450px;
border: 1px solid #f0f0f0;
display: flex;
flex-direction: column;
}
.student-proposal-pannel-title{
display: flex;
justify-content: flex-start;
margin: 20px;
font-size: 15px;
font-weight: 500;
border-bottom: 1px solid #7d8084;
}

</style>