<template>
    <div class="container">
        <div class="setup">
            <span class="title"># 拓课绘-DEMO</span>
            <span class="memo">- 绘本机器人按照您的要求自动绘制绘本</span>
            <span class="memo">- 单次绘制不超过3张</span>
            <span class="memo">- 每次绘制时上传一张用作风格参考的图片</span>
            <span class="memo">- 每次绘制平均耗时在2分钟左右</span>
            <span class="memo">- Demo不支持多人同时使用，如长时间没有生成，请重试</span>
        </div>

        <div class="setup">
            <div class="setup_title">
                <span class="mark">1</span>
                <span class="set_title"> 全局配置</span>
                <span class="set_subtitle"> 设置一次，本次出图都会使用该配置</span>
            </div>
            
            <div class="setup_item">
                <div  class="setup_item_subitem">
                    <span class="setup_item_subitem_spot"> </span>
                    <span class="setup_item_subitem_subtitle"> 上传参考图</span>
                </div>
                <div class="setup_item_subitem">
                    <el-upload
                    class="upload-demo"
                    action="#"
                    :http-request="uploadIMG"
                    multiple
                    :limit="3"
                    :file-list="fileList"
                    :on-exceed="handleExceed"
                   >
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>  
                    <span class="set_subtitle">&nbsp; 最多上传 3 张图片</span> 
                </div>

                <div v-if="uploading" class="upload_progress">
                    <el-progress 
                        :text-inside="true"
                        :stroke-width="15"
                        :percentage="progress">
                    </el-progress>
                </div>

                <div class="uploadTips" v-if="showUploadTips">{{uploadTips}}</div>
            </div>

            <!-- <div class="setup_item">
                <el-table :data="fileList" style="width: 100%">
                    <el-table-column prop="name" label="文件名" />
                    <el-table-column prop="res.size" label="文件体积（KB）"  />
                    <el-table-column prop="res.requestUrls" label="网络地址" />
                   <el-table-column  label="操作" >
                      <template v-slot:default="{row}">
                        <el-button link type="primary" size="small" @click="copyFileUrl(row.requestUrls)">
                          复制链接
                        </el-button>
                      </template>
                    </el-table-column>
                </el-table>
            </div> -->

           
            <el-dialog v-model="dialogTableVisible" title="图片预览" width="800">
                <img src=""/>
            </el-dialog>
           
        </div>

        <div class="setup">
            <div class="setup_title">
                <span class="mark">2</span>
                <span class="set_title"> 生图提示词</span>
                <span class="set_subtitle"> 必须配置正向提示词，可以配置负向提示词</span>
            </div>
            
            <div class="setup_item">
                <span class="setup_item_subtitle">图1 正向提示词</span>
                <div class="setup_item_input">
                    <el-input
                    v-model="positiveText[0]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入正向提示词"
                    />
                </div>
                <span class="setup_item_subtitle">负向提示词</span>
                <el-input
                    v-model="negativeText[0]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入负向提示词"
                />
            </div>
          
            
            
            <div class="setup_item">
                <span class="setup_item_subtitle">图2 正向提示词</span>
                <el-input
                    v-model="positiveText[1]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入正向提示词"
                />

                <span class="setup_item_subtitle">负向提示词</span>
                <el-input
                    v-model="negativeText[1]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入负向提示词"
                />
            </div>

            
            
            <div class="setup_item">
                <span class="setup_item_subtitle">图3 正向提示词</span>
                <el-input
                    v-model="positiveText[2]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入正向提示词"
                />

                <span class="setup_item_subtitle">负向提示词</span>
                <el-input
                    v-model="negativeText[2]"
                    style="width: 240px"
                    autosize
                    type="textarea"
                    placeholder="输入负向提示词"
                />
            </div>

        </div>

        <div class="setup">
            <div class="setup_title">
                <span class="mark">3</span>
            <span class="set_title"> 生图</span>
            <span class="set_subtitle"> 点击 运行 按钮之后等待片刻</span>
            </div>
            
            
            <el-button class="do_req" type="primary" :loading="isLoading" @click="run('ali')">运行</el-button>

            <div v-if="msg!=null" class="res_tips">运行提示</div>
            <div class="res_preview">
                <div  class="res_picture_group"> 
                    <div class="res_picture_group_preview" v-if="img[0]!=null&&img[0]!==''">
                        <el-image
                            :src=img[0]
                            :zoom-rate="1.2"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list=img
                            :initial-index="4"
                            fit="cover" 
                        />

                        <el-button plain="primary" type="primary">下载</el-button>
                    </div>
                    <div v-else>
                        <el-icon :size="45" color="#7d8084" v-loading="imgLoading[0]"><Picture /></el-icon>
                    </div>
                </div>

                <div  class="res_picture_group" >
                    <div class="res_picture_group_preview" v-if="img[1]!=null&&img[1]!==''">
                        <el-image
                            :src=img[1]
                            :zoom-rate="1.2"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list=img
                            :initial-index="4"
                            fit="cover" 
                        />
                        <el-button plain="primary" type="primary">下载</el-button>
                    </div>
                    <div v-else>
                        <el-icon :size="45" color="#7d8084"  v-loading="imgLoading[1]"><Picture /></el-icon>
                    </div>
                </div>

                <div  class="res_picture_group">
                    <div class="res_picture_group_preview" v-if="img[2]!=null&&img[2]!==''">
                        <el-image
                            :src=img[2]
                            :zoom-rate="1.2"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list=img
                            :initial-index="4"
                            fit="cover" 
                        />
                        <el-button plain="primary" type="primary">下载</el-button>
                    </div>
                    <div v-else>
                        <el-icon :size="45" v-loading="imgLoading[2]" color="#7d8084"><Picture /></el-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</template>
   
  <script>
    import {ElMessage,ElButton,ElIcon} from 'element-plus'
    import {Picture} from '@element-plus/icons-vue'
    import axios from 'axios'
    import CryptoJS from 'crypto-js'

    export default {
        name: 'PictureMaster',
        components:{
            ElButton,
            ElIcon,
            Picture,
        },
        data(){
            return{
                    msg:null,
                    img:[null,null,null],
                    imgLoading:[false,false,false],
                    fileList:[],
                    positiveText:[],
                    negativeText:[],
                    uploading:false,
                    progress:0,
                    showUploadTips:false,
                    uploadTips:"",
                    status:[],
                    task_id:[],
                    isLoading:false,
            }
        },
        computed:{
           
        },
        methods: {
            onload(){
                this.showUploadTips=false
                this.uploadTips=""
            },
           
            // 调用生图
            async run(purchers){
                this.isLoading=true
                for(let i=0;i<this.imgLoading.length;i++){
                    this.imgLoading[i]=true
                }
                console.log(this.imgLoading)

                if( purchers=="coze"){
                    const data={
                        'bot_id': '7382506455817519156',
                        'user': 'PictureMasterDemo',
                        'query': '按以下参数画图',
                        'stream': false,
                        
                        'p_ref': this.fileList[0].res.requestUrls[0],
                        'positive_prompt': {
                            'p1_positive_msg': this.positiveText[0],
                            'p2_positive_msg': this.positiveText[1],
                            'p3_positive_msg': this.positiveText[2],
                        },
                        'negative_prompt': {
                            'p1_negative_msg': this.negativeText[0],
                            'p2_negative_msg': this.negativeText[1],
                            'p3_negative_msg': this.negativeText[2]
                        }
                        
                    }
       
                    await axios.post('http://localhost:8000/cozedraw/',data).then((res)=>{
                        console.log("coze画图",res)
                        this.isLoading=false
                    }).catch((error)=>{
                        this.isLoading=false
                        ElMessage({
                            type:'error',
                            message:'分析失败'+error
                        })
                    })
                }else if(purchers=="hy"){
                    console.log("txAuth:",this.txAuth)
                    const data={
                        "Prompt":"7382506455817519156",
                        "Style":"PictureMasterDemo",
                        "Resolution":"按以下参数画图",
                        "custom_variables":{
                            // p_ref:this.fileList[0].res.requestUrls,
                            // positive_prompt:{
                            //     p1_positive_msg:this.positiveText[0],
                            //     p2_positive_msg:this.positiveText[1],
                            //     p3_positive_msg:this.positiveText[2],
                            // },
                            // negative_prompt:{
                            //     p1_negative_msg:this.negativeText[0],
                            //     p2_negative_msg:this.negativeText[1],
                            //     p3_negative_msg:this.negativeText[2]
                            // }
                        }
                    }
                    const headers={
                        "Content-type": "application/json; charset=utf-8",
                        "Authorizatioin":this.txAuth,
                        "Host": "hunyuan.tencentcloudapi.com",
                        "X-TC-Action": "SubmitHunyuanImageJob",
                        "X-TC-Version": "2023-09-01",
                        "X-TC-Timestamp": Date.now(),
                        "X-TC-Region": "ap-beijing"
                    }
                    await axios.post("https://hunyuan.tencentcloudapi.com",data,{headers:headers})
                    .then((res)=>{
                        console.log("run-res",res)
                        
                    }).catch((error)=>{
                        ElMessage({
                            type:'error',
                            message:'分析失败'+error
                        })
                    })
                }else if(purchers=="demo"){
                    setTimeout(()=>{
                        this.img[0]="https://sam-test.oss-cn-beijing.aliyuncs.com/talkcloud_resource/images/run_picture_master%20(3).png"
                        this.img[1]="https://sam-test.oss-cn-beijing.aliyuncs.com/talkcloud_resource/images/run_picture_master%20(4).png"
                        this.img[2]="https://sam-test.oss-cn-beijing.aliyuncs.com/talkcloud_resource/images/run_picture_master%20(5).png"
                    },3000)
                }else if(purchers=="ali"){
                    for(let i=0;i<3;i++){
                        const data={
                        "prompt":this.positiveText[i],
                        "negative_prompt":this.negativeText[i],
                        "ref_img": this.fileList.length!=0? this.fileList[0].res.requestUrls[0] : null
                    }
                    // const that=this
                    await axios.post("http://localhost:8000/subtask/",data)
                    .then((res)=>{
                        console.log("run-res",res.data.res)
                        this.status[i]=res.data.res.output.task_status
                        this.task_id[i]=res.data.res.output.task_id
                        console.log("task_id",this.task_id[i])
                        console.log("status",this.status[i])
                        // 循环查询任务
                        let timer=setInterval(async()=>{
                            await axios.get("http://localhost:8000/fetchtask/?task_id="+this.task_id[i])
                            .then((res)=>{
                                console.log("fetch-res",res.data.res.output.task_status)
                                if(res.data.res.output.task_status=="SUCCEEDED"){
                                    clearInterval(timer)   
                                    this.isLoading=false
                                    console.log("img:"+i.toString(),res.data.res.output.results[0].url) 
                                    this.img[i]=res.data.res.output.results[0].url
                                                               }
                            })
                        },3000)
                    }).catch((error)=>{
                        this.isLoading=false
                        console.log("fetch-error",error)
                        ElMessage({
                            type:'error',
                            message:'分析失败'+error
                        })
                    }) 
                }        
            }
               
            },
            
            getHash(message, encoding = "hex") {
                const hash = CryptoJS.createHash("sha256")
                return hash.update(message).digest(encoding)
            },

            txAuth(){
                const hashedRequestPayload = this.getHash("{}")
                const canonicalRequest =
                "POST" +
                "\n" +
                "/" +
                "\n" +
                "" +
                "\n" +
                "content-type:application/json; charset=utf-8\n" + "host:hunyuan.tencentcloudapi.com\n" +
                "\n" +
                signedHeaders +
                "\n" +
                hashedRequestPayload


                const hashedCanonicalRequest = this.getHash(canonicalRequest)

                const stringToSign =algorithm +
                "\n" +timestamp +"\n" +
                credentialScope +
                "\n" +
                hashedCanonicalRequest

                const algorithm = "TC3-HMAC-SHA256"
                const signedHeaders="content-type;host" 
                const service = "hunyuan"
                const date = new Date(timestamp * 1000)

                const kDate = CryptoJS.SHA256(date, "TC3" + "mwETg7hAQYUDj7Rl05630tiVAfz60qne")
                const kService = CryptoJS.SHA256(service, kDate)
                const kSigning = CryptoJS.SHA256("tc3_request", kService)

                const signature=CryptoJS.SHA256(stringToSign,kSigning,"hex")

                const timestamp = parseInt(String(new Date().getTime() / 1000))

                const credentialScope = date + "/" + service + "/" + "tc3_request"

                return algorithm+" "
                +"Credential="
                +"AKIDjLlz67vYx86C0jGi2APerxyPEsQsDOSL"
                +credentialScope+
                ", "+
                "SignedHeaders="+
                signedHeaders+
                ", "+
                "Signature="+
                signature
            },

            // 上传超限
            handleExceed(){
                ElMessage.warning("最多上传 3 张图片")
            },

            // 上传图片
            uploadIMG(file){
               const that=this
               that.uploading=true
               async function multiUpload(){
                console.log(file.file.name)
                that.$OSS.multipartUpload(file.file.name, file.file, {
                    progress: function(p) {
                    //p进度条的值
                    console.log(p);
                    that.progress = Math.floor(p * 100);
                    }
                })
                .then(result => {
                    //上传成功返回值，可针对项目需求写其他逻辑
                    console.log("文件上传返回值：",result)
                    that.fileList.push(result)
                    //console.log("fileList:",that.fileList[0].res.requestUrls);
                    that.uploading=false
                    that.showUploadTips=true
                    that.uploadTips="上传成功"
                })
                .catch(err => {
                    console.log("err:", err);
                    that.showUploadTips=true
                    that.uploadTips=err.message
                });
               }
               multiUpload()
            },
            copyFileUrl(url){
                // 复制信息到剪贴板上
                console.log(url)
                ElMessage('链接已复制到剪贴板')
            },
        
    },
  }
  </script>
   
<style scoped>
.container{
    display: flex;
    justify-content: center; /*水平居中*/
    align-items: center; /*垂直居中*/
    flex-direction: column;
}
.setup{
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content:flex-start;
    background: #fff;
    width: 75%;
    border: 1px solid rgba(68,74,98,0.15);
    border-radius: 2px;
    box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.title{
    display: flex;
    margin:30px 20px 10px 20px;
    font-size: 15pt;
    font-weight: 600;
    font-size: 14pt;
    color:#7d8084;
}
.memo{
    display: flex;
    margin:10px 20px 3px 40px;
    font-size: 10pt;
    font-weight: 400;
    color:#979a9f;
}
.memo:last-child{
    margin-bottom: 30px;
}

.setup_title{
    display: flex;
    justify-content: flex-start;
    margin: 20px;
    flex-direction: row;
}
.mark{
    display: flex;
    justify-content: center;
    border: 2px solid rgb(152, 156, 161); 
    border-radius: 50px;
    font-size: 9pt;
    font-weight: 800;
    color:#7d8084;
    width: 15px;
    height: 15px;
    margin-right:10px; 
}

.upload_progress{
    display:flex;
    justify-content: flex-start;
    margin-left: 40px;
}
.uploadTips{
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    font-size:10pt;
    color:#f00;
}
.set_title{
    display: flex;
    font-size: 11pt;
    font-weight: 800;
    color:#7d8084;
    margin-right: 10px;
}
.set_subtitle{
    display: flex;
    margin-top: 4px;
    font-size: 9pt;
    font-weight: 400;
    color:#979a9f;
    
}
.setup_item{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px 50px;
}

.setup_item:last-child{
    margin-bottom: 20px;
}
.setup_item_subitem{
    display:flex;
    flex-direction: row;
    margin-top:10px;
}
.setup_item_input{
    display:flex;
    flex-direction: column;
}


.setup_item_subitem_spot{
    width: 5px;
    height:5px;
    background: #7d8084;
    border-radius: 5px;
}
.setup_item_subitem_subtitle{
    font-size: 10pt;
    font-weight: 500;
    color:#7d8084;
    margin:-5px 10px;   
}
.setup_item_subtitle{
    display: flex;
    margin: 4px 15px;
    font-size: 10pt;
    font-weight: 500;
    color:#979a9f;
}
.response{
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content:flex-start;
    background: #fff;
    width: 75%;
    border: 1px solid rgba(68,74,98,0.15);
    border-radius: 2px;
    box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.res_title{
    display: flex;
    font-size: 11pt;
    font-weight: 800;
    color:#7d8084;
    margin-right: 10px;
}
.do_req{
    width: 120px;
    margin: 0 40px;
}
.res_tips{
    display: flex;
    justify-content: flex-start;
    margin: 10px 0 20px 40px;
    font-size: 12px;
    color:#979a9f;
}
.res_preview{
    display:flex;
    flex-direction: row;
    margin: 20px 40px;
    justify-content: center;
}
.res_picture_group{
    display: flex;
    border: 1px solid rgba(68,74,98,0.15);
    margin: 0 40px; 
    width: 240px;
    height: 180px;
    align-items: center;
    justify-content: center;
}
.res_picture_group img{
    width:60%;
    height: 60%;
    margin: 10px 0; 
}
.res_picture_group_preview{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>