<template>
    <div class="container">

    </div>   
</template>
   
  <script>
   

    export default {
        name: 'ParentsMeeting',
        components:{
           
        },
        data(){
            return{
               
            }
        },
       
        methods: {

        
    },
  }
  </script>
   
<style scoped>
.container{
    display: flex;
    justify-content: center; /*水平居中*/
    align-items: center; /*垂直居中*/
    flex-direction: column;
}

</style>