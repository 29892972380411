<template>
    <div class="container">
      <div class="setup">
        <span class="title"># 智能评价</span>
        <span class="memo">- 展示学生学业成绩</span>
        <span class="memo">- 可自动生成学生评语</span>
        <span class="memo">- 可根据老师手写关键词自动生成评语</span>
        <span class="memo">- 评语格式化打印</span>
      </div>

      <div class="student">
          <div class="student-container">
            <span class="student-container-element">3年级 8班</span>
            <span class="student-container-element">班主任：周婷</span>
            <div  class="student-container-studentsList">
              <el-menu style="height:100%" mode="vertical" @select="selectStudent">
                <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index">
                  <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
                  <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
                  <span>{{s.name}}</span>
                </el-menu-item>
              </el-menu>
            </div>
          </div>
          <div class="evaluation-container">
            <div class="evalution-container-exam-scores">
              <el-descriptions border :column="6" title="成绩和考评">
                <el-descriptions-item label="姓名">{{examScore.name}} </el-descriptions-item>
                <el-descriptions-item label="数学成绩">{{examScore.math}} </el-descriptions-item>
                <el-descriptions-item label="语文成绩">{{examScore.chinese}} </el-descriptions-item>
                <el-descriptions-item label="英语成绩">{{examScore.english}} </el-descriptions-item>
                <el-descriptions-item label="体测">{{examScore.pe}} </el-descriptions-item>
              </el-descriptions>
              
              
              <div class="evalution-container-supplementary-words">
                <span class="evalution-container-supplementary-words-title">您对该生的印象(选填):</span>
                <el-input
                    v-model="keyword"
                    style="width: 450px"
                    placeholder="输入个性化印象词，如活泼，爱提问等"
                />
              </div>
              <el-button class="evalution-container-create-comment" @click="eva('ali')" :loading="isLoading" type="primary">生成评价</el-button>
            </div>

            <el-divider/>

            
            <div class="evalution-container-comment">
              <span class="evalution-container-supplementary-words-title">老师评语：</span>
                <el-input
                    v-model="comment"
                    style="width: 480px;"
                    rows="10"
                    type="textarea"
                    placeholder="自动生成老师评语"
                />
            </div>

            <div class="evalution-containe-psave"> 
              <el-button>保存评价</el-button>
              <el-button>打印</el-button>
            </div>
            
          </div>
      </div>

       
    </div>   
</template>
   
  <script>
    //import axios from 'axios'
    import {ElMessage} from 'element-plus'
    export default {
        name: 'EvaluationPage',
        components:{
        },
        data(){
            return{
                   //socket:null,
                   keyword:"",
                   comment:"",
                   studentsList:[
                    {name:"张小帅",sex:"1",id:1},
                    {name:"马小红",sex:"0",id:2}
                  ],
                  examScore:{name:'-',math:'-',chinese:'-',english:'-',pe:'-'},
                  scores:[
                    {math:'98',chinese:'99',english:'95',pe:'87'},
                    {math:'54',chinese:'89',english:'99',pe:'100'} 
                  ],
                  isLoading:false,
                    
            }
        },
        // created(){
        //   this.$socket.on('connect',()=>{
        //     console.log('已连接到服务器')
        //   })
        // },
        // beforeUnmount() {
        //   this.$socket.disconnect()
        // },
        computed:{
           
        },
        methods: {
          
          selectStudent(e){
            console.log(e)
            this.examScore=this.scores[e]
            this.examScore.name=this.studentsList[e].name
            this.comment=""
            this.keyword=""
          },
         
          async eva(LLM){
            this.comment=""
            this.isLoading=true
            let data={}
            let  req_url=""

            if(LLM=="coze"){
                req_url="http://localhost:8000/eva/" 
                data={
                    //"bot_id":"7387323234830090267",
                    "bot_id":"7389814020196958242",
                    "user":"班主任",
                    "query":"填写评语",
                    "stream":true,
                    "custom_variables":{
                            "math":this.examScore.math,
                            "chinese":this.examScore.chinese,
                            "english":this.examScore.english,
                            "pe":this.examScore.pe,
                            "student_name":this.examScore.name,
                            "keywords":this.keyword
                        }
              }
              }else if(LLM=="ali"){
                req_url="http://localhost:8000/ali-eva/"
                data={
                            "math":this.examScore.math,
                            "chinese":this.examScore.chinese,
                            "english":this.examScore.english,
                            "pe":this.examScore.pe,
                            "student_name":this.examScore.name,
                            "keywords":this.keyword
                }
              }

              const res=await fetch(req_url,{
                method:'POST',
                body:JSON.stringify(data),
                headers:{
                  "Content-Type":"application/json",
                }
              })
              if(!res.ok){
                
                console.log('请求发送失败',res)
                ElMessage({
                  type:'error',
                  message:'请求发送失败'
                })
              }
              const reader=res.body.getReader()
              let {value,done}=await reader.read()
              //console.log("value=",value)
              while (!done) {  
                ({ value, done } = await reader.read());  
                this.comment+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
                if (done) {  
                  break; // 跳出循环，因为流已经结束  
                }  
              }  
              this.isLoading=false
              },

    },
  }
  </script>
   
<style scoped>
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.title{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  font-size: 14pt;
  color:#7d8084;
}
.memo{
  display: flex;
  margin:10px 20px 3px 40px;
  font-size: 10pt;
  font-weight: 400;
  color:#979a9f;
}
.memo:last-child{
  margin-bottom: 30px;
}

.student{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content:flex-start;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}

.student-container{
  margin:20px;
}
.student-container-element{
  font-size: 11pt;
  font-weight: 600;
  margin-right:10px; 
}

.student-container-studentsList{
  margin-top:20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x:hidden; 
}

.evaluation-container{
  margin: 20px;
}

.evalution-container-supplementary-words{
  margin-top:20px;
  display: flex;
  align-content: flex-start;
}

.evalution-container-supplementary-words-title{
  font-size: 11pt;
  margin:10px;
}

.evalution-container-create-comment{
  margin:10px 0 -10px -500px;
}

.evalution-container-comment{
  display: flex;
  flex-direction: row;
}

.evalution-containe-psave{
  display: flex;
  flex-direction: row;
  margin:20px 0;
}
</style>