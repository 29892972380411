<template>
    <div class="container">

      <div class="setup">
        <span class="title"># 作业助手</span>
        <span class="memo">- 自动分科布置作业</span>
        <span class="memo">- 自动批改作业</span>
        <span class="memo">- 查看学生历史作业</span>
        <span class="memo">- 提供智能学习建议</span>
      </div>
     

      <div class="pannel">
        <div class="top-navigator">
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <span class="grade">4年级8班  班主任：周婷</span>
            <el-tab-pane label="布置作业" name="first">
              <div class="assign-homework">
                <div class="subject-list">
                  <el-cascader-panel :options="options" @change="selectedSubject"  />
                </div>              
                <div v-if="selectSubject.length!=0" class="content-pannel">
                  <div v-for="(i,index) in questionsGroup" :key="index">
                    <div class="content-pannel-select-type">
                      <span class="content-pannel-select-title">选择题目类型</span>
                      <el-radio-group v-model="questionType[index]" @change="changeQuestionType(index)">
                        <el-radio :value="1">填空题</el-radio>
                        <el-radio :value="2">选择题</el-radio>
                        <el-radio :value="3">判断题</el-radio>
                        <el-radio :value="4">问答题</el-radio>
                      </el-radio-group>
                    </div>
                    <div v-if="questionType[index]!=null" class="content-pannel-select-quantity">
                      <span class="content-pannel-select-title">选择题目数量</span>
                      <el-input-number v-model="questionNum[index]" :min="0" :max="2" 
                      @change="handleChangeQusetionNum(index)" />
                    </div>
                    <div v-for="(j,key) in questions[index]" :key="key" class="content-pannel-questions">
                      <span class="content-pannel-questions-stem">{{j.stem}}</span>
                      <el-button type="primary" link  class="content-pannel-questions-change" @click="newOne(j.id,key,index)">换一题</el-button>
                    </div>
                    <el-divider/>
                  </div>
                  <div  v-if="questionNum.length!=0" class="content-pannel-add">
                    <el-button @click="addNewType(questionsGroup.length+1)">添加新类型</el-button>
                    <el-button @click="preview">预览</el-button>
                    <el-button>打印</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="作业管理" name="second">
              <div class="check-homework">
                <div class="student-container-studentsList">
                  <el-menu style="height:100%" mode="vertical" @select="selectStudent">
                    <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index">
                      <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
                      <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
                      <span>{{s.name}}</span>
                    </el-menu-item>
                  </el-menu>
                </div>
                
                    <div class="student-container-homework-pannel">
                      <div class="student-container-homework-pannel-subject-tray">    
                        <span class="student-container-homework-pannel-subject-tray-title">
                            数学
                        </span>
                        <el-button @click="submitHomework"  style="margin:0 20px"  plain type="primary" v-if="stuIndex!=null">上传作业</el-button>
                        <el-card class="student-container-homework-pannel-subject-tray-card" v-for="(i,index) in mathHomework[stuIndex]" :key="index">
                            <div class="student-container-homework-pannel-subject-tray-card-status-tag">
                              <el-tag type="primary" v-if="i.status==1">已提交</el-tag>
                              <el-tag type="danger" v-else>未提交</el-tag>
                            </div>
                          
                            <div class="student-container-homework-pannel-subject-tray-card-title">
                              <span class="student-container-homework-pannel-subject-tray-card-title-name">{{ i.title }}</span>
                              <span v-if="i.status==1" class="student-container-homework-pannel-subject-tray-card-title-cr">正确率：{{ i.cr }}</span>
                            </div>
                          
                            <div class="student-container-homework-pannel-subject-tray-card-description">
                              <span class="student-container-homework-pannel-subject-tray-card-description-words">发布时间：{{i.date}}</span>
                            </div>
                        </el-card>
                      </div>
    
                      <div class="student-container-homework-pannel-subject-tray">
                        <span class="student-container-homework-pannel-subject-tray-title">
                          语文
                        </span>
                        <el-button @click="submitHomework" style="margin:0 20px"  plain type="primary" v-if="stuIndex!=null">上传作业</el-button>
                        <el-card class="student-container-homework-pannel-subject-tray-card" v-for="(i,index) in chineseHomework[stuIndex]" :key="index">
                          <div class="student-container-homework-pannel-subject-tray-card-status-tag">
                            <el-tag type="primary" v-if="i.status==1">已提交</el-tag>
                            <el-tag type="danger" v-else>未提交</el-tag>
                          </div>
                        
                          <div class="student-container-homework-pannel-subject-tray-card-title">
                            <span class="student-container-homework-pannel-subject-tray-card-title-name">{{ i.title }}</span>
                            <span v-if="i.status==1" class="student-container-homework-pannel-subject-tray-card-title-cr">正确率：{{ i.cr }}</span>
                          </div>
                        
                          <div class="student-container-homework-pannel-subject-tray-card-description">
                            <span class="student-container-homework-pannel-subject-tray-card-description-words">发布时间：{{i.date}}</span>
                          </div>
                      </el-card>
                      </div>
    
                      <div class="student-container-homework-pannel-subject-tray">
                        <span class="student-container-homework-pannel-subject-tray-title">
                          英语
                        </span>
                        <el-button  @click="submitHomework"  style="margin:0 20px" plain type="primary" v-if="stuIndex!=null">上传作业</el-button>
                        <el-card class="student-container-homework-pannel-subject-tray-card" v-for="(i,index) in englishHomework[stuIndex]" :key="index">
                          <div class="student-container-homework-pannel-subject-tray-card-status-tag">
                            <el-tag type="primary" v-if="i.status==1">已提交</el-tag>
                            <el-tag type="danger" v-else>未提交</el-tag>
                          </div>
                        
                          <div class="student-container-homework-pannel-subject-tray-card-title">
                            <span class="student-container-homework-pannel-subject-tray-card-title-name">{{ i.title }}</span>
                            <span v-if="i.status==1" class="student-container-homework-pannel-subject-tray-card-title-cr">正确率：{{ i.cr }}</span>
                          </div>
                        
                          <div class="student-container-homework-pannel-subject-tray-card-description">
                            <span class="student-container-homework-pannel-subject-tray-card-description-words">发布时间：{{i.date}}</span>
                          </div>
                      </el-card>
                      </div>
    
                    </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="智能学习建议" name="third">
              <div class="wisdom-proposal">
                <div class="student-container-studentsList">
                  <el-menu style="height:100%" mode="vertical" @select="getStudentSituation">
                    <el-menu-item v-for="(s,index) in studentsList" :key="index" :index="index">
                      <el-icon v-if="s.sex==0" :size="12" class="no-inherit"  :style="{color:'#ff6699'}"><Avatar/></el-icon>
                      <el-icon v-else :size="12" class="no-inherit"  :style="{color:'#0066cc'}"><Avatar /></el-icon>
                      <span>{{s.name}}</span>
                    </el-menu-item>
                  </el-menu>
                </div>

                <div class="student-situation-pannel">
                  <span class="student-situation-pannel-title">总结学生情况</span>
                  <span  class="student-situation-pannel-description">{{stuSituation[stuIndex]}}</span>
                </div>

                <div class="student-proposal-pannel">
                  <span class="student-proposal-pannel-title">填写学习建议</span>
                  <el-input type="textarea" :autosize="{minRows:6,maxRows:10}"></el-input>
                  <el-button plain type="primary">一键生成</el-button>
                </div>
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
      </div>

      <el-dialog
      v-model="previewDialog"
      title="作业打印预览"
      width="700"
      :before-close="handleClose"
    >
      <span class="preview-title" v-if="questions[0]!=null">填空题</span>
      <span class="preview-info" v-for="(f,index) in questions[0]" :key="index">{{f.stem}}</span>

      <span  class="preview-title" v-if="questions[1]!=null">选择题</span>
      <span class="preview-info"  v-for="(c,index) in questions[1]" :key="index">{{c.stem}}</span>

      <span class="preview-title" v-if="questions[2]!=null">判断题</span>
      <span class="preview-info"  v-for="(j,index) in questions[2]" :key="index">{{j.stem}}</span>

      <span class="preview-title" v-if="questions[3]!=null">问答题</span>
      <span class="preview-info"  v-for="(q,index) in questions[3]" :key="index">{{q.stem}}</span>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleClose">关闭</el-button>
          <el-button type="primary" @click="handleClose">
            打印
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="scanHomework"
      title="批改作业"
      width="700"
      :before-close="scanHomeworkClose"
    >

        <div class="scan-homework-body">

          <div class="scan-zone">
            <div v-if="ocrProgressing!=2" class="scanning-overlay">
            </div>
            <div> 
              <el-tag v-if="ocrProgressing==0"> 请将作业放在此区域内</el-tag>
              <img v-if="ocrProgressing!=0" style="width:300px;height:auto" src="../assets/mathhomework.png" />
           </div>
          </div>
          <div class="correcting-zone">

              <span v-if="ocrProgressing==2" class="correcting-zone-title">自动批改</span>

              <span v-if="ocrProgressing==2" class="correcting-zone-description">1 ………… √</span>
              <span v-if="ocrProgressing==2" class="correcting-zone-description">2 ………… √</span>
              <span v-if="ocrProgressing==2" class="correcting-zone-description">3 ………… X</span>
              <span v-if="ocrProgressing==2" class="correcting-zone-description">4 ………… √</span>
              <span v-if="ocrProgressing==2" class="correcting-zone-description">5 ………… X</span>
              <span v-if="ocrProgressing==2" class="correcting-zone-description">6 ………… √</span>
           
              <span v-if="ocrProgressing==2" class="correcting-zone-total">答题准确率：4/6</span>

          </div>
        </div>


      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="scanHomeworkClose">
            完成
          </el-button>
        </div>
      </template>
    </el-dialog>

    </div>   
</template>
   
  <script>
  import axios from 'axios'
  import {ElMessage} from 'element-plus'
   export default {
        name: 'HomeWork',
        components:{
          
        },
       
        data(){
            return{
                      activeName: "first", // 被激活的tab_Key

                      selectSubject:[],  // 选中的科目
                      questionType:[],// 选中的题型 1=填空题，2=选择题，3=判断题，4=问答题
                      questionsGroup:[1], // 题目组
                      questionNum:[],  // 每组题目的数量
                      questions:[[]], // 每组里的题目

                      previewDialog:false, // 预览窗口 

                      options: [  // 科目类型mock
                        {
                                      value: 'math',
                                      label: '数学',
                                      children: [
                                        {
                                          value: 'm1',
                                          label: '大数的认识',
                                        },
                                        {
                                          value: 'm2',
                                          label: '公顷和平方千米',
                                        },
                                      ]
                                    },
                                    {
                                      value: 'chinese',
                                      label: '语文',
                                      children: [
                                        {
                                          value: 'c1',
                                          label: '观潮',
                                        },
                                        {
                                          value: 'c2',
                                          label: '走月亮',
                                        },
                                      ]
                                    },
                                    {
                                      value: 'english',
                                      label: '英语',
                                      children: [
                                        {
                                          value: 'e1',
                                          label: 'Unit 1 My classroom',
                                        },
                                        {
                                          value: 'e2',
                                          label: 'Unit 2 My schoolbag',
                                        },
                                      ]
                                    },
                            
                          ],
                            studentsList:[
                              {name:"张小帅",sex:"1",id:1},
                              {name:"马小红",sex:"0",id:2}
                            ],
                                    
                            subjects:"",

                            subjectsItems:[
                            {
                              value: 'math',
                              label: '数学',
                            },
                            {
                              value: 'chinese',
                              label: '语文',
                            },
                            {
                              value: 'english',
                              label: '英语',
                            },
                          ],
                          stuIndex:null,
                          mathHomework:[
                            [
                              {date:"2024-07-11",title:"公顷和平方千米",status:1,cr:"4/5"},
                              {date:"2024-07-09",title:"大数的认识",status:1,cr:"5/5"}
                            ],
                            [
                              {date:"2024-07-11",title:"公顷和平方千米",status:0,cr:"0/5"},
                              {date:"2024-07-09",title:"大数的认识",status:1,cr:"2/5"} 
                            ]
                          ],
                          chineseHomework:[
                            [
                              {date:"2024-07-11",title:"观潮",status:1,cr:"4/5"},
                              {date:"2024-07-09",title:"走月亮",status:1,cr:"5/5"}
                            ],
                            [
                              {date:"2024-07-11",title:"观潮",status:0,cr:"0/5"},
                              {date:"2024-07-09",title:"走月亮",status:1,cr:"2/5"} 
                            ]
                          ],
                          englishHomework:[
                            [
                              {date:"2024-07-11",title:"Unit 1 My classroom",status:1,cr:"4/5"},
                              {date:"2024-07-09",title:"Unit 2 My schoolbag",status:1,cr:"5/5"}
                            ],
                            [
                              {date:"2024-07-11",title:"Unit 1 My classroom",status:0,cr:"0/5"},
                              {date:"2024-07-09",title:"Unit 2 My schoolbag",status:1,cr:"2/5"} 
                            ]
                          ],
                          scanHomework:false,
                          ocrProgressing:0,

                          stuSituation:[],
            }
        },
        
        methods: {
         
          handleClick() {
            console.log();
          },
          selectedSubject(value){
            this.selectSubject=value;
            console.log('选中项的值（完整路径）：', this.selectSubject);  
          },
         
          changeQuestionType(index){
            console.log('题目类型：', this.questionType[index]);
            this.questionNum[index]=0
            console.log("this.questionNum[index]:",this.questionNum[index])
            if(this.questionNum[index]==0){ 
              try{this.questions[index].length=0}catch(e){console.log("什么都不需要做")}
            }
            if(this.questionType[index]==1){
              console.log('单选题')
            }else if(this.questionType[index]==2){
              console.log('多选题')
            }else if(this.questionType[index]==3){
              console.log('判断题')
            }else if(this.questionType[index]==4){
              console.log('填空题')
            }
          },
         
          handleChangeQusetionNum(index){
            console.log('题目数量：', this.questionNum[index]);

            if (index >= this.questions.length) {  
              // 使用 Array.fill 填充空数组到指定长度  
              this.questions.length = index + 1;  
              this.questions.fill(null, this.questions.length - 1); // 注意：这里其实不需要填充 null，因为数组默认就是空的  
              // 但如果你想要初始化每个新组为一个空数组，可以这样做：  
              // this.questions.fill([], this.questions.length - 1, index + 1);  
              // 不过在这个场景中，我们只需要确保数组长度足够即可，因为接下来会赋值  
            }  
            const data={
              "subject":this.selectSubject[0],
              "term":this.selectSubject[1],
              "type":this.questionType[index]
            }
            const headers={
              'Content-type': 'application/json'
            }
            axios.post('http://localhost:8000/get_question_stem/',data,headers).then(res=>{
              console.log("res:",res.data.res)
              this.questions[index]=Array.from({length:this.questionNum[index]},(_,i)=>({
              stem:`${res.data.res[i].stem}`,
              id:res.data.res[i].id,
              records:res.data.res
            }));
            console.log("this.questions:",this.questions)
            }).catch(err=>{
              ElMessage({
                        type:'error',
                        message:err
                    })
            })
          },

          newOne(_id,key,index){
            const records=this.questions[index][key].records
            console.log("newOne:",records)
            // 换一道新题
            records.forEach(element => {
                if(element.id!=_id){
                  this.questions[index][key].stem=element.stem
                }
            }); 
          },
          addNewType(value){
            this.questionsGroup=Array.from({length:value})
          },
          preview(){
            this.previewDialog=true
          },
          handleClose(){
            console.log("关闭对话框")
            this.previewDialog=false
          },
          selectStudent(index){
            console.log(index) 
            this.stuIndex=index
          },
          submitHomework(){
            this.scanHomework=true
            setTimeout(()=>{
              this.ocrProgressing=1
              setTimeout(()=>{
              this.ocrProgressing=2
            },2000)
            },3000)
            
            console.log("this.ocrProgressing:",this.ocrProgressing)
          },
          scanHomeworkClose(){
            this.scanHomework=false
          },
          async getStudentSituation(index){
            this.stuIndex=index
            const req_url="http://localhost:8000/ali-stu-situation/"
                const zhangData={
                            "math":"作业提交2次，《大数认识》作业正确率100%，《公顷和平方千米》作业正确率100%",
                            "chinese":"作业提交2次，《观海》作业正确率80%，阅读理解和判断题各错一道。《走月亮》作业正确率100%",
                            "english":"作业提交1次，《Unit1 My classroom》作业正确率40%，第2，3，4题错误。《My schoolbag》作业未提交",
                            "student_name":"张小帅",
                }
                const maData={
                            "math":"作业提交2次，《大数认识》作业正确率50%，第1，2，3题错误。《公顷和平方千米》作业正确率50%。第4，7，9题错误",
                            "chinese":"作业提交2次，《观海》作业正确率100%，《走月亮》作业正确率100%",
                            "english":"作业提交2次，《Unit1 My classroom》作业正确率70%，第3，8题错误。《My schoolbag》作业正确率100%",
                            "student_name":"马小红",
                }

                const res=await fetch(req_url,{
                method:'POST',
                body:JSON.stringify(index==0?zhangData:maData),
                headers:{
                  "Content-Type":"application/json",
                }
              })
              if(!res.ok){
                
                console.log('请求发送失败',res)
                ElMessage({
                  type:'error',
                  message:'请求发送失败'
                })
              }
              const reader=res.body.getReader()
              let {value,done}=await reader.read()
              //console.log("value=",value)
              while (!done) {  
                ({ value, done } = await reader.read());  
                this.stuSituation[index]+=this.$extract_stream_response_string(new TextDecoder("utf-8").decode(value))
                if (done) {  
                  break; // 跳出循环，因为流已经结束  
                }  
              }  
          }
    }
  }
  </script>
   
<style scoped>
.container{
    display: flex;
    justify-content: center; /*水平居中*/
    align-items: center; /*垂直居中*/
    flex-direction: column;
}
.setup{
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content:center;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.title{
  display: flex;
  margin:30px 20px 10px 20px;
  font-size: 15pt;
  font-weight: 600;
  font-size: 14pt;
  color:#7d8084;
}
.memo{
  display: flex;
  margin:10px 20px 3px 40px;
  font-size: 10pt;
  font-weight: 400;
  color:#979a9f;
}
.memo:last-child{
  margin-bottom: 30px;
}
.pannel{
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content:flex-start;
  background: #fff;
  width: 75%;
  border: 1px solid rgba(68,74,98,0.15);
  border-radius: 2px;
  box-shadow: 0.55px 0px 0.1px 0.1px rgba(68,74,98,0.25); /* 水平偏移量 | 垂直偏移量 | 模糊距离 | 扩展距离 | 颜色 */  
}
.top-navigator{
  margin:20px;
}
.grade{
  display: flex;
  justify-content: left;
  margin: 10px 5px;
  font-weight: bold;
}
.assign-homework{
  display: flex;  
  flex-direction: row;
}
.subject-list{
  display: flex;
}
.content-pannel{
  min-width:600px;
  display: flex;
  margin-left:20px;
  margin-right:10px;
  border: 1px solid rgba(221, 221, 221,0.85);
  border-radius: 5px;
  flex-direction: column;
  padding-top:20px;
}
.content-pannel-select-title{
  font-size:14px;
  margin:10px 20px;
  display: flex;
}
.content-pannel-select-type{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.content-pannel-select-quantity{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.content-pannel-questions{
  background-color: #f5f5f5;
  margin:10px 20px 20px 90px;
  display: flex;
  flex-direction: column;
}
.content-pannel-questions-stem{
  font-weight:500;
  font-size: 16px;
  margin: 10px 10px;
  word-wrap:normal;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.content-pannel-questions-change{
  font-size: 14px;
  color:#0c67dd;
  cursor: pointer;
  margin: 12px 10px; 
  display:flex;
  justify-content: flex-start;
}
.content-pannel-add{
  display: flex;
  justify-content: flex-start;
  margin: -10px 0 10px 20px;
}
.preview-title{
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  display:flex;
  justify-content: flex-start;
  margin: 20px;
}
.preview-info{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin:20px;
}
.preview-info:last-child{
  margin: 20px;
}

.check-homework{
  display: flex;
  flex-direction: row;
}
.student-container-studentsList{
  margin-top:20px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x:hidden; 
}
.student-container-homework-pannel{
  display:flex;
  overflow-x: auto;
  max-width: 800px;
  background:#fcfcfc;
}
.student-container-homework-pannel-subject-tray{
  flex-shrink: 0;
  display: flex;
  width: 350px;
  flex-direction: column;
  border-radius: 10px;
}

.student-container-homework-pannel-subject-tray-title{
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin: 10px 20px;
}
.student-container-homework-pannel-subject-tray-card{
  display: flex;
  justify-content: flex-start;
  margin: 10px 20px;
  cursor:pointer;
}

.student-container-homework-pannel-subject-tray-card-status-tag{
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 0;
}

.student-container-homework-pannel-subject-tray-card-title{
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 0; 
}

.student-container-homework-pannel-subject-tray-card-description{
  display: flex;
  justify-content: flex-start;
}

.student-container-homework-pannel-subject-tray-card-title-name{
  font-size: 16px;
  font-weight: 450;
  word-wrap: normal;
  display:block;
  margin-right:30px;
}
.student-container-homework-pannel-subject-tray-card-title-cr{
  display:block;
  font-size: 16px;
  font-weight: 400;
  color:#0c67dd;
}
.student-container-homework-pannel-subject-tray-card-description-words{
  font-size: 13px;
  font-weight: 300;
  color:#7d8084;
}

.scan-homework-body{
  display: flex;
  flex-direction: row;
}
.scan-zone{
  position: relative;
  height: 600px; /* 根据需要调整高度 */
  width:400px;
  overflow: hidden;
  border: 1px solid rgba(31, 74, 159, 0.85);
  border-radius: 10px;
  padding-top:60px; 
  text-align: center;
}
.correcting-zone{
 display:flex; 
 flex-direction: column;
 margin-left:20px;
}

@keyframes scan-effect {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.scanning-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 191, 255, 0.357), transparent); /* 扫描效果的颜色渐变 */
    animation: scan-effect 2s linear infinite
}

.correcting-zone-title{
  display: inline-block;
  font-size: 16px;
  font-weight: 450;
  margin: 20px 0;
}
.correcting-zone-description{
  display: inline-block;
  font-size: 14px;
  font-weight: 450;
  margin: 20px 0; 
}
.correcting-zone-total{
  display: inline-block;
  font-size: 16px;
  font-weight: 550;
  color:#0c67dd;
  margin: 20px 0;
}

.wisdom-proposal{
  display: flex;
  flex-direction: row; 
}

.student-situation-pannel{
  width:450px;
  background: #f8f8fb;
  margin-left:10px;
  display: flex;
  flex-direction: column;
}
.student-situation-pannel-title{
  display: flex;
  justify-content: flex-start;
  margin: 20px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #7d8084;
}
.student-situation-pannel-description{
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 450;
  margin: 0 20px;
  line-height: 28px;
}
.student-proposal-pannel{
  width:450px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
}
.student-proposal-pannel-title{
  display: flex;
  justify-content: flex-start;
  margin: 20px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #7d8084;
}

</style>